import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Typography,
  Divider,
  TextField,
  Box,
  Container,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";

import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MainButton from "src/components/Button/MainButton/MainButton";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";

function ScheduleRoom({
  open,
  handleClosebox,
  url,
  room,
  scheduleData,
  FetchRoomData,
  myMeeting,
}) {
  const scheduledata = {
    title: room ? room.name : "",
    description: "",
    startDate: "",
    endDate: "",
    guestEmail: "",
    url: url ? url : "",
    roomid: room ? room.id : "",
    public_view: false,
  };
  const [formData, setFormData] = useState(scheduledata);
  const [checkStart, setCheckStart] = useState(false);
  const [checkEnd, setCheckEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (scheduleData) {
      setFormData({
        title: scheduleData?.title,
        description: scheduleData?.description,
        startDate: scheduleData?.startDate,
        endDate: scheduleData?.endDate,
        guestEmail: scheduleData?.guestEmail,
        url: scheduleData?.url,
        public_view: scheduleData?.public_view,
      });
    } else {
      setFormData(scheduledata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  const handleClose = () => {
    handleClosebox();
  };

  const handleCheckStartDates = (newvalue) => {
    if (formData?.startDate) {
      const currentDate = new Date();
      // const startDate = new Date(formData.startDate);
      const startDate = newvalue;
      if (startDate < currentDate) {
        setCheckStart(true);
      } else {
        setCheckStart(false);
      }
    }
  };
  const handleCheckEndDate = (newvalue) => {
    if (formData?.endDate && formData?.startDate) {
      const startDate = new Date(formData.startDate);
      // const endDate = new Date(formData.endDate);
      const endDate = newvalue;

      if (endDate <= startDate) {
        setCheckEnd(true);
      } else {
        setCheckEnd(false);
      }
    }
  };

  const handleChange = (field, newvalue) => {
    let value = newvalue;
    if (field === "guestEmail") {
      setError(false);
    }
    if (field === "startDate" || field === "endDate") {
      const formateDate = formatDateString(value);
      if (formateDate) {
        value = formateDate;
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const checkfields = () => {
    const currentDate = new Date();
    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.endDate);
    let fieldError;
    if (!formData.startDate || startDate < currentDate) {
      fieldError = true;
      setCheckStart(true);
    }
    if (!formData.endDate || endDate <= startDate) {
      fieldError = true;
      setCheckEnd(true);
    }
    if (!formData.guestEmail) {
      fieldError = true;
      setError(true);
    }
    if (error === true) {
      fieldError = true;
      setError(true);
    }

    if (fieldError) {
      return fieldError;
    } else {
      return false;
    }
  };

  const CreateSheduleMeeting = async () => {
    try {
      const checkAllField = checkfields();

      if (checkAllField === false) {
        setLoading(true);
        const response = await apiClients.sheduleMeeting(formData);
        if (response) {
          setLoading(false);
          if (myMeeting) {
            FetchRoomData();
          }

          toast.success("Meeting Scheduled");
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function formatDateString(dateString) {
    console.log(dateString, "dateString");
    const date = new Date(dateString);

    const options = {
      timeZone: "Asia/Kolkata", // Specify the time zone explicitly
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  }

  const updateScheduleMeeting = async () => {
    try {
      const response = await apiClients.updateScheduleMeeting(
        scheduleData.id,
        formData
      );
      if (response.success === true) {
        FetchRoomData();
        toast.success(response.message);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopy = (id) => {
    if (id === true) {
      navigator.clipboard.writeText(scheduleData?.url);
    } else {
      navigator.clipboard.writeText(
        `${window.location.origin}/room/${id}/join`
      );
    }

    toast.success(
      "The meeting URL has been copied. The link can be used to join the meeting."
    );
  };

  const handleEmailBlur = () => {
    // Validate emails using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailList = formData?.guestEmail
      .split(",")
      .map((email) => email.trim());
    const invalidEmails = emailList.filter(
      (email) => !emailPattern.test(email)
    );

    if (invalidEmails.length > 0) {
      setError(true);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          Schedule Meeting
        </DialogTitle>
        <Divider />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DialogContent>
            <Box sx={{ mt: 6 }}>
              <Container>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {/* <InputLabel> Title</InputLabel> */}
                    <Typography
                      gutterBottom
                      style={{
                        wordBreak: "break-word",
                        fontSize: `calc(0.5rem + 1vw)`,
                        // fontWeight: 500,
                      }}
                    >
                      {formData?.title}
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          label="Description"
                          value={formData?.description}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid
                        item
                        xs={1}
                        mt={2}
                        sx={{
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        <AccessTimeIcon />
                      </Grid>
                      <Grid item xs={12} sm={5} md={5}>
                        <MobileDateTimePicker
                          label="Start date"
                          value={
                            formData?.startDate
                              ? new Date(formData?.startDate)
                              : null
                          }
                          inputFormat="dd/MM/yyyy hh:mm a"
                          onChange={(date) => {
                            handleChange("startDate", date);
                            // setCheckStart(false);
                            handleCheckStartDates(date);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth error={true} />
                          )}
                        />
                        {checkStart && (
                          <Typography variant="caption" color="error">
                            Invalid Data
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={5} md={5}>
                        <MobileDateTimePicker
                          label="End date"
                          value={
                            formData?.endDate
                              ? new Date(formData.endDate)
                              : null
                          }
                          inputFormat="dd/MM/yyyy hh:mm a"
                          onChange={(date) => {
                            handleChange("endDate", date);
                            // setCheckEnd(false);
                            handleCheckEndDate(date);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth

                              // error={Boolean(touched.end && errors.end)}
                              // helperText={touched.end && errors.end}
                              // InputProps={{
                              //     endAdornment: (
                              //         <InputAdornment position="end">
                              //             <DateRangeIcon />
                              //         </InputAdornment>
                              //     )
                              // }}
                            />
                          )}
                        />
                        {checkEnd && (
                          <Typography variant="caption" color="error">
                            Invalid Data
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid
                        item
                        xs={1}
                        mt={2}
                        sx={{
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        <AccessTimeIcon />
                      </Grid>
                      <Grid item xs={12} sm={5} md={5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                          <MobileDateTimePicker
                            label="Start date"
                            value={
                              formData?.startDate
                                ? new Date(formData?.startDate)
                                : null
                            }
                            inputFormat="dd/MM/yyyy hh:mm a"
                            onChange={(date) => {
                              handleChange("startDate", date);
                              handleCheckStartDates(date);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={checkStart}
                              />
                            )}
                          />
                          {checkStart && (
                            <Typography
                              variant="caption"
                              color="error"
                              sx={{ mt: 1 }}
                            >
                              Invalid Data
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={5} md={5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                          <MobileDateTimePicker
                            label="End date"
                            value={
                              formData?.endDate
                                ? new Date(formData.endDate)
                                : null
                            }
                            inputFormat="dd/MM/yyyy hh:mm a"
                            onChange={(date) => {
                              handleChange("endDate", date);
                              handleCheckEndDate(date);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={checkEnd}
                              />
                            )}
                          />
                          {checkEnd && (
                            <Typography
                              variant="caption"
                              color="error"
                              sx={{ mt: 1 }}
                            >
                              Invalid Data
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid
                        item
                        xs={1}
                        mt={2}
                        sx={{
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        <VideocamOutlinedIcon />
                      </Grid>
                      <Tooltip title={"Copy Meeting URL"}>
                        <Grid item xs={12} md={10}>
                          <MainButton
                            onClick={() => {
                              handleCopy(room ? room?.friendly_id : true);
                            }}
                            style={{ display: "flex", gap: "10px" }}
                          >
                            Join with atlearn meeting
                            <ContentCopyIcon />
                          </MainButton>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={1}
                        mt={3}
                        sx={{
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        <PersonIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          fullWidth
                          multiline
                          label="Add Guest"
                          variant="outlined"
                          value={formData?.guestEmail}
                          onChange={(e) =>
                            handleChange("guestEmail", e.target.value)
                          }
                          onBlur={handleEmailBlur}
                          error={error}
                          helperText={error ? "Invalid email(s) format" : ""}
                          placeholder="Enter comma-separated emails"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={1}
                        mt={1}
                        sx={{
                          display: { xs: "none", sm: "block" },
                        }}
                      ></Grid>
                      <Grid item xs={11}>
                        <FormControlLabel
                          label="Public View"
                          control={
                            <Checkbox
                              checked={formData?.public_view}
                              // indeterminate={checked[0] !== checked[1]}
                              onChange={(e) =>
                                handleChange("public_view", e.target.checked)
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={{ xs: "flex", sm: "flex" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      gap={1}
                    >
                      <SecondaryButton onClick={handleClosebox}>
                        Cancel
                      </SecondaryButton>
                      {scheduleData ? (
                        <MainButton onClick={updateScheduleMeeting}>
                          {loading && (
                            <CircularProgress
                              size={"1.2rem"}
                              sx={{ color: "white", mr: 1 }}
                            />
                          )}
                          update Meeting
                        </MainButton>
                      ) : (
                        <MainButton onClick={CreateSheduleMeeting}>
                          {loading && (
                            <CircularProgress
                              size={"1.2rem"}
                              sx={{ color: "white", mr: 1 }}
                            />
                          )}
                          Schedule Meeting
                        </MainButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </DialogContent>
        </LocalizationProvider>
      </Dialog>
    </div>
  );
}

export default ScheduleRoom;
