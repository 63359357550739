import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Features from "../../components/Features";
import Header from "../../components/Header";
import InfoSection from "../../components/InfoSection";
import backgroundcta from "../../assets/img/backgroundd-cta1.webp";
import Footer from "src/components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  studentDataManagement,
  bulkEnrollment,
  simplifySchool,
  atLearnBook,
  atLearnPoll,
  courseManagementFeatures,
  features,
  AtLearnExperience,
  CourseManagementInfo,
  CourseManagementCards,
  CoursemanagementInfo,
} from "src/Data/schoolPageData";
import InfoCard from "src/components/InfoCard";
import { useEffect } from "react";
const School = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <Header
        // backgroundImage={"/cover_images/school.svg"}
        backgroundImage={"/cover_images/Booksimge.png"}
        pageTitle={"School"}
        subTitle={
          "Your path to excellence starts here empower your learning journey with Atlearn"
        }
        cta={"Join now"}
      />
      <div id="student-data-management" className="mt-4 md:mt-10">
        <InfoSection info={studentDataManagement} rounded />
      </div>

      <div id="user-management" className="mt-4 md:mt-8">
        <InfoSection info={CourseManagementInfo} />
        <InfoCard cardData={CourseManagementCards} />
      </div>

      <div id="course-management" className="mt-4 md:mt-8">
        <InfoSection info={CoursemanagementInfo} />
        <Accordion items={courseManagementFeatures} />
      
      </div>
   

      <div id="bulk-enrollment" className="mt-4 md:mt-10">
        <InfoSection info={bulkEnrollment} imageSide="left" />
      </div>

      <div id="simplify-school" className="mt-4 md:mt-10">
        <InfoSection info={simplifySchool} />
      </div>

      <div id="features" className="mt-4 md:mt-10">
        <InfoSection info={AtLearnExperience} />
        <Features features={features} />
      
      </div>

      <div id="book-management" className="mt-4 md:mt-10">
        <InfoSection info={atLearnBook} imageSide="left" />
      </div>

      <div id="polls" className="mt-4 md:mt-10 mb-4 md:mb-10">
        <InfoSection info={atLearnPoll} rounded />
      </div>

      <section
        className="mt-8 text-neutral-700 bg-no-repeat bg-cover py-28"
        id="section-1"
        style={{
          backgroundPosition: "50% 50%",
          backgroundImage: `url(${backgroundcta})`,
        }}
      >
        <div className="flex m-auto">
          <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
            <div className="content-start flex-wrap flex w-full">
              <section className="w-full">
                <div className="flex max-w-[82.50rem] m-auto" id="div-2">
                  <div className="flex min-h-[0.06rem] md:w-full" id="div-3">
                    <div className="content-start flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <div
                            className="bg-fuchsia-950 md:rounded-bl-full md:rounded-br-full md:rounded-tl-full md:rounded-tr-3xl rounded-xl sm:p-20 p-10 max-w-[38.13rem]"
                            id="div-4"
                          >
                            <h2 className="text-white text-[30px] leading-none font-semibold -mt-1 mb-5 font-fredoka">
                              Simplify your school's learning experience with
                              Atlearn
                            </h2>

                            <p className="text-zinc-300 text-lg mb-8 font-fredoka">
                              Empowering institutions to foster growth and
                              achievement through customized educational tools.
                            </p>

                            <Button
                              onClick={() => navigate("/signup")}
                              text={"Start Registration"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default School;
