import Header from "../components/Header";
import Footer from "../components/Footer";
import PhoneIcon from "../assets/images/contact/phone.svg";
import ContactIcon from "../assets/images/contact/clock.svg";
import EmailIcon from "../assets/images/contact/email.svg";
import ContactForm from "src/components/ContactForm";
const Contact = () => {
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/contactimg.svg"}
        pageTitle={"Contact"}
        subTitle={
          "Get in touch with us for any questions or support—we're here to help"
        }
        cta={"Schedule a Call"}
      />

      <div>
        <section className="text-neutral-700 pb-24 pt-28" id="section-1">
          <div className="flex m-auto">
            <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
              <div className="content-start flex-wrap flex w-full">
                <section className="w-full">
                  <div
                    className="flex max-w-[82.50rem] justify-center items-center m-auto flex-wrap"
                    id="div-2"
                  >
                    <div
                      className="flex min-h-[0.06rem] md:w-[33.333%]"
                      id="div-3"
                    >
                      <div
                        className="content-start flex-wrap px-4 flex w-full"
                        id="div-4"
                      >
                        <div
                          className="flex-col justify-center w-full"
                          style={{
                            alignItems: "initial",
                          }}
                        >
                          <div id="div-5">
                            <div
                              className="py-8 px-5 min-w-[15rem] text-center flex justify-center items-center flex-col mb-8 border-4 border-yellow-400 border-solid rounded-3xl"
                              id="div-6"
                            >
                              <div className="bg-yellow-400 flex justify-center mb-7 rounded-full w-28 h-28">
                                <img
                                  alt=""
                                  className="w-7 h-auto max-w-full"
                                  id="img-1"
                                  src={PhoneIcon}
                                />
                              </div>

                              <h3 className="text-black text-xl font-semibold mb-1 font-fredoka">
                              Contact Us
                              </h3>

                              <p className="text-lg -mb-1 font-fredoka">
                                +91 8015801639
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex min-h-[0.06rem] md:w-[33.333%]"
                      id="div-3"
                    >
                      <div
                        className="content-start flex-wrap px-4 flex w-full"
                        id="div-4"
                      >
                        <div
                          className="flex-col justify-center w-full"
                          style={{
                            alignItems: "initial",
                          }}
                        >
                          <div>
                            <div className="py-8 px-5 text-center min-w-[15rem] flex justify-center items-center flex-col mb-8 border-4 border-yellow-400 border-solid rounded-3xl">
                              <div className="bg-yellow-400 flex justify-center mb-7 rounded-full w-28 h-28">
                                <img
                                  alt=""
                                  className="w-12 h-auto max-w-full"
                                  id="img-2"
                                  src={ContactIcon}
                                />
                              </div>

                              <h3 className="text-black text-xl font-semibold mb-1 font-fredoka">
                                Support
                              </h3>

                              <p className="text-lg -mb-1 font-fredoka">Monday to Friday : 10am -6pm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex min-h-[0.06rem] md:w-[33.333%]"
                      id="div-3"
                    >
                      <div
                        className="content-start flex-wrap px-4 flex w-full"
                        id="div-4"
                      >
                        <div
                          className="flex-col justify-center w-full"
                          style={{
                            alignItems: "initial",
                          }}
                        >
                          <div id="div-5">
                            <div
                              className="py-8 px-5 min-w-[15rem] text-center flex justify-center items-center flex-col mb-8 border-4 border-yellow-400 border-solid rounded-3xl"
                              id="div-6"
                            >
                              <div className="bg-yellow-400 flex justify-center mb-7 rounded-full w-28 h-28">
                                <img
                                  alt=""
                                  className="w-14 h-auto max-w-full"
                                  id="img-3"
                                  src={EmailIcon}
                                />
                              </div>

                              <h3 className="text-black text-xl font-semibold mb-1 font-fredoka">
                                Email Address
                              </h3>

                              <p className="text-lg -mb-1 font-fredoka">
                              support@atlearn.in
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ContactForm />

      <Footer />
    </div>
  );
};

export default Contact;
