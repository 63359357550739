import Accordion from "src/components/Accordion";
import Features from "src/components/Features";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import HeaderText from "src/components/HeaderText";
import InfoCard from "src/components/InfoCard";
import InfoSection from "src/components/InfoSection";
import Paragraph from "src/components/Paragraph";
import Benifits from "src/assets/images/Services/teachers/Benifits.webp";
import backgroundcta from "src/assets/img/backgroundd-cta1.webp";
//data files
import {
  overView,
  courseManagement,
  StudentEngagement,
  AssessmentGrading,
  CommunicationTools,
  ResourceLibrary,
  CustomizationPersonalization,
  OnlineVirtualClasses,
  courseManagementInfo,
  StudentEngagementInfo,
} from "src/Data/teachersData";
import Button from "src/components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Teachers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/teacher.svg"}
        // backgroundImage={"/cover_images/ExperiencetheBestofPersonalized.png"}
        subTitle={
          "Learn anytime, anywhere—unlock your potential with Atlearn's online classes"
        }
        cta={"Get Started Now"}
        pageTitle={"Teachers"}
      />
      <div>
        <InfoSection info={overView} />
      </div>

      <div id="course-management">
        <InfoSection info={courseManagementInfo} text_center={true} />
        <InfoCard cardData={courseManagement} />
      </div>

      <div id="student-engagement">
        <InfoSection info={StudentEngagementInfo} text_center={true} />
        <Features features={StudentEngagement} />
      </div>

      <div id="assessment-grading" className="mt-8">
        <InfoSection info={AssessmentGrading} imageSide />
      </div>

      <section className="text-neutral-700  relative " id="communication-tools">
        <div className="relative flex max-w-[82.50rem] m-auto flex-wrap px-3.5">
          <div className="flex-col justify-center w-full mb-[calc(60px_-_22px)]">
            <HeaderText>Communication Tools</HeaderText>
            <Paragraph>
              Facilitate seamless communication between educators, students, and
              parents via messaging and announcements.
            </Paragraph>
          </div>
        </div>
        <div>
          <Accordion items={CommunicationTools} />
        </div>
      </section>

      <div id="resource-library" className="mt-8">
        <InfoSection info={ResourceLibrary} />
      </div>

      <div id="customization-personalization" className="">
        <InfoSection info={CustomizationPersonalization} imageSide />
      </div>

      <div id="online-virtual-classes" className=" mb-2">
        <InfoSection info={OnlineVirtualClasses} />
      </div>

      <div
        id="benefis"
        style={{
          backgroundImage: `url(${Benifits})`,
          backgroundSize: "cover", // Ensures the image covers the entire div
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat",
        }}
        className="mt-10  h-[150px]  md:h-[300px] lg:h-[600px] w-full"
        // className="mt-10  h-[150px]   w-full"
      ></div>

      <section
        className="mt-8 text-neutral-700 bg-no-repeat bg-cover py-28"
        id="section-1"
        style={{
          backgroundPosition: "50% 50%",
          backgroundImage: `url(${backgroundcta})`,
        }}
      >
        <div className="flex m-auto">
          <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
            <div className="content-start flex-wrap flex w-full">
              <section className="w-full">
                <div className="flex max-w-[82.50rem] m-auto" id="div-2">
                  <div className="flex min-h-[0.06rem] md:w-full" id="div-3">
                    <div className="content-start flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <div
                            className="bg-fuchsia-950 md:rounded-bl-full md:rounded-br-full md:rounded-tl-full md:rounded-tr-3xl rounded-xl sm:p-20 p-10 max-w-[38.13rem]"
                            id="div-4"
                          >
                            <h2 className="text-white text-[30px] leading-none font-semibold -mt-1 mb-5 font-fredoka">
                              Simplify your teaching with Atlearn
                            </h2>

                            <p className="text-zinc-300 text-lg mb-8 font-fredoka">
                              Transform your classroom with resources designed
                              to fit your teaching style and enhance student
                              engagement.
                            </p>

                            <Button
                              onClick={() => navigate("/signup")}
                              text={"Start Registration"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Teachers;
