import managementHub from "src/assets/images/Services/LMS/managementHub-1.webp";
import Effectivetooltolearn from "src/assets/images/Services/LMS/Effectivetooltolearn-1.webp";
import Dataanalysis from "src/assets/images/Services/LMS/Dataanalysis-1.webp";
import Onlinetest from "src/assets/images/Services/LMS/Onlinetest-1.gif";
import communicationIcon from "src/assets/icons/communication.png";
import AnnouncementIcon from "src/assets/icons/announcement.png";
import calenderIcon from "src/assets/icons/calender.png";
import assessmentIcon from "src/assets/icons/assessment.png";
import PerformanceIcon from "src/assets/icons/performance.png";
import visulizationIcon from "src/assets/icons/datavisualization.png";
import Personalizeslearning from "src/assets/images/Services/LMS/Personalizeslearning-1.webp";
import EfficientDigitalImage from "src/assets/images/Services/LMS/EfficientDigitalImage-1.webp";
import Forum from "src/assets/images/Services/LMS/Forum-1.webp";
import courseManagementIcon1 from "src/assets/images/Services/courseManagementIcon1.svg";
import courseManagementIcon2 from "src/assets/images/Services/courseManagementIcon2.svg";
import courseManagementIcon3 from "src/assets/images/Services/courseManagementIcon3.svg";
import StudentManagement from "src/assets/images/Services/LMS/EffortlessCourseCreation-1.webp";
import AssignmentIntegration from "src/assets/images/Services/LMS/AssignmentIntegration.webp";
import DiverseActivity from "src/assets/images/Services/LMS/DiverseActivity.webp";
import bulkenrollmentIcon from "src/assets/images/Services/bulkenrollmentIcon.svg";
import groupIcon from "src/assets/images/Services/groupIcon.svg";
import TaskAssignmentIcon from "src/assets/images/Services/TaskAssignmentIcon.svg";
import GroupManagement from "src/assets/images/Services/LMS/GroupManagement.webp";
import TaskAssignment from "src/assets/images/Services/LMS/TaskAssignment.webp";
import Enrollment from "src/assets/images/Services/LMS/Enrollment-1.webp";
import AssignmentIcon from "src/assets/images/Services/AssignmentIcon.svg";
import userrole from "src/assets/images/Services/UserRolesIcon.svg";
import CourseAssignmentIcon from "src/assets/images/Services/CourseAssignmentIcon.svg";
import UserRoles from "src/assets/images/Services/LMS/UserRoles.webp";
import CourseAssignment from "src/assets/images/Services/LMS/CourseAssignment.webp";
import BulkEnrollment from "src/assets/images/Services/LMS/BulkEnrollment.webp";
import LearningWithPolls from "src/assets/images/Services/LMS/LearningWithPolls.gif";
export const CourseManagement = {
  title: "Course Management",
  description:
    "You can easily build a course using Atlearn by clicking a button. You can add categories, subcategories, and subtopics as well as the course material. On the course page, you can also add Lessons, and several assignments and make a list of quizzes using the question bank. You can also add various activities from the list of activities.",
};

export const CourseManagementCards = [
  {
    imgSrc: StudentManagement,
    color: "bg-lime-600",
    iconSrc: courseManagementIcon1,
    heading: "Effortless Course Creation",
    description:
      "Build courses with a single click, adding categories, subcategories, subtopics, and course material seamlessly.",
  },
  {
    imgSrc: AssignmentIntegration,
    color: "bg-cyan-500",
    iconSrc: courseManagementIcon2,
    heading: "Comprehensive Lesson and Assignment Integration",
    description:
      "Add lessons, multiple assignments, and quizzes using the question bank directly on the course page.",
  },
  {
    imgSrc: DiverseActivity,
    color: "bg-fuchsia-950",
    iconSrc: courseManagementIcon3,
    heading: "Diverse Activity Options",
    description:
      "Enhance your course by selecting from a variety of activities to keep learners engaged and interactive. From quizzes and discussions to hands-on projects and multimedia content, you can tailor learning experiences to suit every style.",
  },
];

export const StudentManagementInfo = {
  title: "Student Management",
  description:
    "The teacher can manually enroll students or the administrator can enroll them automatically, or the students can enroll themselves. If classes that are doing the same course need to be divided up, or if tasks need to be differentiated, students can also be added to groups.",
};

export const StudentManagementCard = [
  {
    imgSrc: Enrollment,
    color: "bg-lime-600",
    iconSrc: bulkenrollmentIcon,
    heading: "Flexible Enrollment Options",
    description:
      "The system allows for multiple methods of student enrollment, including manual enrollment by teachers, automatic enrollment by administrators, and self-enrollment by students.",
  },
  {
    imgSrc: GroupManagement,
    color: "bg-cyan-500",
    iconSrc: groupIcon,
    heading: "Group Management",
    description:
      "The system supports the division of students into different groups, which can be useful for managing classes doing the same course or for differentiating tasks among students.",
  },
  {
    imgSrc: TaskAssignment,
    color: "bg-fuchsia-950",
    iconSrc: TaskAssignmentIcon,
    heading: "Differentiated Task Assignment",
    description:
      "Teachers can assign different tasks to different groups of students, enabling tailored instruction and more effective classroom management.",
  },
];

export const UserManagementInfo = {
  title: "User Management",
  description:
    "You can create multiple user roles such as admin, manager, student, teacher, or non-editing teacher and assign them to the course of their Interest. You also define a new user role and name them. Multiple Users can be uploaded in bulk enrolment also which helps the administrator saves time.",
};

export const UserManagementCards = [
  {
    imgSrc: UserRoles,
    color: "bg-lime-600",
    iconSrc: userrole,
    heading: "Customizable User Roles",
    description:
      "The system allows the creation of multiple predefined user roles (admin, manager, student, teacher, non-editing teacher) and also supports the creation and naming of new custom user roles to fit specific needs.",
  },
  {
    imgSrc: CourseAssignment,
    color: "bg-cyan-500",
    iconSrc: CourseAssignmentIcon,
    heading: "Role-Based Course Assignment",
    description:
      "Users can be assigned to courses based on their roles and areas of interest, ensuring that each user has access to relevant course materials and functionalities.",
    textColor: "text-cyan-500",
  },
  {
    imgSrc: BulkEnrollment,
    color: "bg-fuchsia-950",
    iconSrc: AssignmentIcon,
    heading: "Bulk Enrollment",
    description:
      "The ability to upload multiple users at once for bulk enrollment significantly streamlines the administrative process, saving time and effort for administrators.",
  },
];

export const ManagementHub = {
  title: "Centralized Classroom Management Hub",
  description:
    "Atlearn provides a unified platform for course setup, student management, and classroom creation, with tools enhancing engagement and collaboration",
  points: [
    "Unified platform for course setup and student management",
    "Versatile tools for classroom creation and user roles.",
    "Comprehensive monitoring of course progress.",
    "Integration of engaging teaching tools.",
  ],
  image: managementHub,
};

export const EffectiveTools = {
  title: "Effective Tools To Keep Learners Engaged",
  description:
    "Engage students effectively with Atlearn Collaborative Tools, facilitating seamless communication and interaction with teachers. Features include a motivating leaderboard for group participation and achievement",
  points: [
    "Seamless communication and interaction with teachers.",
    "Motivating leaderboard for group participation.",
    "Encouragement of student engagement and achievement.",
    "Facilitation of fun and goal achievement.",
  ],
  image: Effectivetooltolearn,
  cta: "Discover More",
  url: "/",
};

export const Reports = {
  title: "Reports And Analytics",
  description:
    "Drive teaching strategy with Atlearn reports and analytics, providing insights for informed decision-making and course improvement",
  points: [
    "Examine attendance reports",
    "Help in Class performance evaluation",
    "Enhance learner retention",
    "Downloaded Reports in Excel format",
    "xamine the amount of time spent in class",
    "Reduce your expenses and efforts",
    "Examine your course performance",
    "Sort your class data",
  ],
  image: Dataanalysis,
  cta: "Get Started Today",
  url: "/",
};

export const EducationGap = {
  title: "Find The Education Gap At The Right Time",
  description: "",
  points: [
    "Evaluate yourself with tests on any subject.",
    "Cover all topic areas conveniently.",
    "Create quizzes for specific students or groups.",
    "Boost IQ and engagement with educational games.",
    "Improve performance and confidence with personalized evaluation.",
    "Utilize assessment results to tailor learning experiences.",
    "Foster communication between parents, teachers, and students for effective support.",
  ],
  image: Onlinetest,
};

export const features = [
  {
    icon: communicationIcon,
    title: "Interactive Communication",
    description:
      "Promote interactive learning through real-time communication and collaboration.",
  },
  {
    icon: AnnouncementIcon,
    title: "Announcements",
    description: "Keep learners updated with important news and events.",
  },
  {
    icon: calenderIcon,
    title: "Calendar Coordination",
    description:
      "Manage schedules and deadlines efficiently with shared calendars.",
  },
  {
    icon: assessmentIcon,
    title: "Assessment Tools",
    description:
      "Evaluate learning progress comprehensively with tests and quizzes.",
  },
  {
    icon: PerformanceIcon,
    title: "Performance Tracking",
    description: "Provide detailed feedback and monitor progress effectively.",
  },
  {
    icon: visulizationIcon,
    title: "Data Visualization",
    description:
      "Analyze student performance trends through graphs and statistics.",
  },
];

export const Assessment = {
  title: "Assessment And Quiz",
  description:
    "Utilize clear quiz questions with varied rules to gauge student understanding. Atlearn LMS quizzes support exams, pre-tests, and feedback collection",
  points: [
    "Create polls, tests, quizzes, and resume them seamlessly.",
    "Utilize visual materials and provide feedback effortlessly.",
    "Personalize certificates and categorize quizzes for organization.",
    "Utilize a question bank and control the number of tries.",
    "Easily publish or email test results and analyze them.",
    "Automate training progress monitoring and reduce costs.",
    "Simplify material customization and offer convenient access to courses.",
  ],
  image: Personalizeslearning,
  cta: "Create a Quiz",
  url: "/",
};

export const EfficientDigital = {
  title: "Efficient Digital Book Management In Atlearn",
  description:
    "In Atlearn, the book-related feature primarily focuses on the effective management and distribution of a wide array of educational materials, including course content, textbooks, and supplementary reading resources. This feature provides a centralized platform for instructors to organize, upload, and administer digital books, ensuring seamless access to essential learning materials for both educators and students within the learning environment.",
  points: [
    "Instructors can seamlessly upload, organize, and manage diverse digital books.",
    "Arrange books into folders or categories for efficient content structuring.",
    "Compatibility with multiple file formats (PDF, ePUB, Word, etc.) ensures flexibility.",
    "Robust search capabilities facilitate quick access to specific books or content.",
    "Navigation tools such as bookmarks and table of contents enhance user experience.",
    "Annotation tools enable adding notes, highlights, and annotations to digital books.",
    "Track student progress within digital books to monitor engagement effectively.",
    "Download books for offline access, ensuring learning flexibility and accessibility.",
  ],
  image: EfficientDigitalImage,
};

export const AtlearnForum = {
  title: "Atlearn Forum",
  description:
    "Discover your go-to online community for Learning Management Systems! Connect, share experiences, and stay updated on educational technology trends",
  points: [
    "Engage in vibrant discussions with educators and administrators, sharing insights and gaining wisdom.",
    "Connect with experienced experts to collaboratively tackle challenges and find solutions.",
    "Learn effective course creation, learner engagement, and evaluation techniques in LMS.",
    "Gain insights from fellow educators on effective approaches in diverse educational environments.",
  ],
  image: Forum,
};
export const Empowering = {
  title: "Empowering Interactive Learning With Polls In Atlearn",
  description:
    "It enables instructors to effortlessly incorporate polls into course materials, quizzes, or discussions, thereby enriching interactive learning encounters. Users can integrate poll data with learning analytics to provide instructors with valuable insights into student engagement and comprehension trends.The polls functionality within Atlearn serves as a valuable instrument to boost engagement, collect feedback, and assess student comprehension. Poll creation is a feature available to instructors, enabling them to formulate questions and provide multiple-choice options. This functionality serves as a means to evaluate comprehension, gather opinions, or foster reflective thinking.",
  image: LearningWithPolls,
};

export const LMS = {
  title: "LMS Communication Tools To Interact",
  description:
    "Enhance student engagement and critical thinking skills with Atlearn LMS Communication features, fostering interactive learning and retention.",
};

export const Personalized = {
  title: "Personalized Learning Paths",
  description:
    "Managing a large number of students and online courses in an LMS can be challenging. Personalized learning paths make the journey easier for both administrators and learners by assisting learners in grasping a specific topic or training program.Drive Education Cut down Cost using our LMS An organization can launch and track training efforts with the help of a learning management system (LMS), which allows them to centralize its training management. The company could be a university, a business, or a school.",
};
