import React, { useEffect } from "react";
import { blogContent } from "src/Data/blogData";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function BlogList() {
  const navigate = useNavigate();
  const { searchQuery } = useSelector((state) => state.searchBlog);
  const filteredContent = blogContent.filter((section) =>
    section.heading.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div id="blogs">
      {filteredContent.length > 0 ? (
        filteredContent.map((section, index) => (
          <div  className="px-4 w-full max-w-full mt-3.5 min-[992px]:w-full">
            <div className="mb-8 border-4 border-yellow-400 border-solid rounded-3xl">
              <div className="bg-slate-100 rounded-tl-3xl rounded-tr-3xl -mt-1 -mr-1 -ml-1 overflow-hidden text-rose-600">
                <a href="https://atlearn.in/a-very-warm-welcome-to-our-new-treasurer/">
                  <img
                    alt=""
                    className="cursor-pointer w-[52.92rem] h-auto max-w-full"
                    src={section?.image}
                  />
                </a>
              </div>
              <div className="pb-0 px-10 pt-6" id="div-22">
                <div className="mb-1 text-rose-600 font-medium">
                  <span className="inline-block mr-5" id="span-9">
                    <a
                      className="inline-block"
                      href="https://atlearn.in/2023/08/09/"
                      id="a-14"
                    >
                      <i className="cursor-pointer inline-block mr-1.5" />
                      <div className="cursor-pointer">{section?.date}</div>
                    </a>
                  </span>
                  <span className="inline-block" id="span-10">
                    <a
                      className="inline-block"
                      href="https://atlearn.in/a-very-warm-welcome-to-our-new-treasurer/#respond"
                      id="a-15"
                    >
                      <i className="cursor-pointer inline-block mr-1.5" />0
                      Comments
                    </a>
                  </span>
                </div>
                <h2
                  className="text-black text-[2.88rem] leading-none font-semibold capitalize mb-3.5"
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  {section?.heading}
                </h2>
                {section.paragraphs?.map((paragraph, pIndex) => (
                  <p
                    key={pIndex}
                    className="mb-6"
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  />
                ))}
                {/* <p className="mb-6" id="p-5">
              Lorem ipsum dolor sit amet, consectetur adipisc ing elit, sed do
              eiusmod tempor incididunt ut la bore et dolore magna aliqua Lorem
              ipsum dolor sit amet, consectetur adipisc ing elit, sed do eiusmod
              tempor incididunt ut la bore et dolore magna aliqua.
            </p> */}
                <div
                  className=" cursor-pointer hover:bg-primary text-white bg-secondary font-semibold py-5 px-10 text-center capitalize align-middle inline-block -mb-6 rounded-full overflow-hidden"
                  id="a-16"
                  onClick={() => navigate(`/blog/${section?.heading}`)}
                >
                  Read More
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full m-auto min-[576px]:max-w-[33.75rem]  md:max-w-[45.00rem] min-[992px]:max-w-[60.00rem] min-[1200px]:max-w-[71.25rem] min-[1400px]:max-w-[82.50rem] min-[1300px]:max-w-[calc(1290px_+_30px)] min-[1300px]:pl-[calc(30px_/_2)] min-[1300px]:pr-[calc(30px_/_2)]">
          <div className="flex items-center justify-center min-h-64 ">
            <h1 className="text-2xl font-bold text-gray-800 p-2">
              No blog posts found for "{searchQuery}
            </h1>
          </div>
        </div>
      )}
      {}
    </div>
  );
}

export default BlogList;
