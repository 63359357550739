import Header from "../components/Header";
import HeaderText from "../components/HeaderText";
import InfoSection from "../components/InfoSection";
import Paragraph from "../components/Paragraph";
import teacherIcon from "../assets/images/about/noun-teacher.png";
import SchoolIcon from "../assets/images/about/school.svg";
import studentIcon from "../assets/images/about/students.svg";
import CoursesIcon from "../assets/images/about/courses.svg";

import Footer from "src/components/Footer";
import {
  onlineLearningSolutions,
  InnovativeSolutionsInfo,
  EmpoweringLearning,
  writersToDreamBig,
} from "src/Data/aboutPageData";
import { useRef } from "react";
const About = () => {
  const countInfo = [
    { icon: CoursesIcon, number: "20+", label: "Courses", size: "w-12" },
    { icon: studentIcon, number: "200+", label: "Students", size: "w-12" },
    { icon: SchoolIcon, number: "20+", label: "Schools", size: "w-14" },
    { icon: teacherIcon, number: "23", label: "Teachers", size: "w-20" },
  ];

  const sectionRef = useRef(null);

  const handleScrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/about.svg"}
        pageTitle={"About"}
        subTitle={
          "Redefining learning with technology that adapts to your needs and helps you thrive"
        }
        cta={"Explore Our Solutions"}
        handleScrollToSection={handleScrollToSection}
      />

      <div ref={sectionRef} className="mt-10">
        <InfoSection info={EmpoweringLearning} />
      </div>
      <div ref={sectionRef} className="mt-10">
        <InfoSection info={onlineLearningSolutions} imageSide="left" />
      </div>
      <div>
        <InfoSection info={writersToDreamBig} />
      </div>

      <div>
        <section className="text-neutral-700 relative w-full mt-8">
          <div className="relative flex max-w-[82.50rem] m-auto" id="div-1">
            <div className="relative flex min-h-[0.06rem] md:w-full" id="div-2">
              <div className="content-start flex-wrap px-4 relative flex w-full">
                <div
                  className="flex-col justify-center relative w-full"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div>
                    <div className="text-center mb-[calc(60px_-_22px)]">
                      <HeaderText>Innovative Solutions</HeaderText>
                      <Paragraph>
                        Revolutionizing online education with cutting-edge
                        technology.
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="text-neutral-700 relative w-full mt-8">
        <section className="relative flex max-w-[82.50rem] m-auto">
          <div className="flex flex-wrap justify-center gap-8 w-full">
            {InnovativeSolutionsInfo?.map((section, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center lg:text-left w-full sm:w-full md:w-1/3 lg:w-1/5"
              >
                <div className="w-28 h-28 mb-5">
                  <img
                    src={section.img}
                    alt={section.title}
                    className="w-full h-full object-cover rounded-full shadow-lg"
                  />
                </div>
                <h3
                  className="text-3xl font-semibold text-gray-900 mb-4 text-center"
                  style={{ minHeight: "4.5rem" }}
                >
                  {section.title}
                </h3>
                <ul className="space-y-2 text-left">
                  {section.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex items-center text-base"
                    >
                      <span className="mr-2 text-secondary">&#x25CF;</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="font-fredoka mt-14 mb-8">
        <section className="text-neutral-700 w-full" id="section-1">
          <div className="flex max-w-[82.5rem] m-auto" id="div-1">
            <div className="flex w-full lg:w-full" id="div-2">
              <div
                className="flex-wrap justify-center items-center px-4 flex w-full gap-2"
                id="div-3"
              >
                {countInfo?.map((item, index) => (
                  <div
                    className="flex-col justify-center items-center w-auto max-w-full"
                    key={index}
                    id={`div-${index + 4}`}
                  >
                    <div className="bg-white shadow-lg rounded-lg p-6 w-full">
                      <div className="mr-9">
                        <div className="items-center flex mb-8">
                          <div className="text-black bg-slate-100 text-center inline-block mr-5 rounded-full">
                            <img
                              alt=""
                              className={`${item.size} h-auto`}
                              src={item.icon}
                            />
                          </div>
                          <div className="flex-grow font-semibold text-center">
                            <p className="text-rose-600 md:text-[5.63rem] text-[60px] leading-none">
                              {item.number}
                            </p>
                            <p className="text-black text-lg">{item.label}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default About;
