import onlineLearnImg from "src/assets/img/online_learning_solutions.webp";
import liveconferencesImg from "src/assets/images/about/live_conferences1.webp";
import onlineClassImg from "src/assets/img/image1.webp";
import LiveMeetingImg from "src/assets/img/image2.webp";
import ElarnImg from "src/assets/images/Blogs/image2.webp";
import learningImg from "src/assets/images/Blogs/image3.webp";
import writers from "src/assets/images/about/writers.webp";
export const EmpoweringLearning = {
  title: "Empowering learning, anywhere, anytime",
  description:
    "Our Learning Management System (LMS) provides a comprehensive, user-friendly platform for students, educators, and administrators to create, manage, and engage with educational content anytime, anywhere.",
  points: [
    "Easily create and tailor courses to meet specific learning objectives.",
    "Integrates videos, audio, and interactive content for an enhanced learning experience.",
    "Monitor student performance with real-time data and detailed reports.",
    "Facilitate student interaction through discussion forums and group projects.",
    "Access the platform from any device, ensuring learning on the go.",
  ],
  image: onlineLearnImg,
  cta:"Learn More",
  url:"/"
};
export const onlineLearningSolutions = {
  title: "Comprehensive Online Learning Solutions",
  description:
    "Our Online Classroom platform combines interactive learning with seamless online meeting capabilities, offering educators and students a dynamic, virtual learning experience from any location.",
  points: [
    "Conduct real-time classes with integrated video conferencing tools for interactive learning.",
    "Record sessions for students to revisit lessons and review missed content at their convenience.",
    "Utilize polls, Q&A, and chat features to boost student participation during live sessions.",
    "Share presentations, documents, and use a digital whiteboard for a more immersive teaching experience.",
    "Upload assignments, grade submissions, and provide feedback directly through the platform.",
    "Facilitate group discussions and collaborative work with virtual breakout rooms.",
  ],
  image: liveconferencesImg,
   cta:"Join Online Class",
  url:"/"
};

export const writersToDreamBig = {
  title: "Empowering young writers to dream big!",
  description: `Our Kids Online <a href="https://storylane.in" class="text-blue-600 ">Story Writing Platform</a>  encourages creativity and literacy by providing a fun and safe space where young writers can craft their own stories, share them, and explore their imagination.`,
  points: [
    "Easy-to-use templates and prompts to help kids start writing their stories.",
    "A user-friendly interface that allows kids to easily navigate through their writing projects, resources, and activities.",
    "A curated collection of storybooks, with colourful illustrations to spark creativity and enhance writing skills.",
    "An accessible tool that allows kids to dictate their stories, making the writing process easier and more enjoyable.",
    "Participate in fun challenges and competitions to motivate kids to write regularly.",
    "Track writing progress and celebrate achievements with badges and rewards.",
  ],
  image: writers,
  cta:"Start Writing",
  url:"/"
};

export const liveConferences = {
  title: "Live Conferences And Meetings",
  description:
    "Live Conferences and Meetings: Our platform facilitates real-time interaction and collaboration with customizable features, ensuring a secure environment.",
  points: [
    "Real-time interaction facilitation",
    "Robust online meeting platform",
    "Fostered collaboration and engagement",
    "Customizable features for personalized experiences",
  ],
  image: liveconferencesImg,
};

export const testimonials = [
  {
    text: "Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text. It's not Latin, though it looks like it, and it actually says nor is",
    name: "Jannie Marko",
  },
  {
    text: "From its medieval origins to the digital era, learn everything there is to know about the ubiquitous lorem ipsum passage sometimes known, is dummy. ",
    name: "Vivi Marian",
  },
];

export const InnovativeSolutionsInfo = [
  {
    title: "Online Classroom",
    img: onlineClassImg,
    features: ["Accessibility", "Interactivity", "Engagement", "Flexibility"],
  },
  {
    title: "Live Meeting",
    img: LiveMeetingImg,
    features: [
      "Real-time Collaboration",
      "Seamless Connectivity",
      "Multimedia Integration",
      "Customizable Features",
    ],
  },
  {
    title: "E-Learning",
    img: ElarnImg,
    features: [
      "Comprehensive Resources",
      "Personalized Learning Paths",
      "Interactive Assessments",
      "Progress Tracking",
    ],
  },
  {
    title: "Continuous Learning",
    img: learningImg,
    features: [
      "Skill Development Focus",
      "Lifelong Learning Promotion",
      "Dynamic Team Collaboration",
      "User-Centric Approach",
    ],
  },
];
