import { Navigate, useRoutes } from "react-router-dom";
// layouts
// import SimpleLayout from "./layouts/simple";
import ProfileDashboardLayout from "./layouts/dashboard/ProfileDashboardLayout";
import OrganizationLayout from "./layouts/dashboard/OrganizationLayout";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import RecordingPage from "./pages/RoomRecording/RecordingPage";
import MangeUsers from "./pages/admin-organization/ManageUser/MangeUsers";
import ServerRoom from "./pages/admin-organization/ServerRoom/ServerRoom";
import ServerRecordings from "./pages/admin-organization/ServerRecordings/ServerRecordings";
import SiteSettings from "./pages/admin-organization/SiteSettings/SiteSettings";
import RoomConfiguration from "./pages/admin-organization/RoomConfiguration/RoomConfiguration";
import Role from "./pages/admin-organization/Role/Role";
import Moderator from "./pages/room/Moderator/Home/Home";
import SingleRoom from "./pages/room/Moderator/SingleRoom/SingleRoom";
// ----------------------------------------------------------------------
import { AuthProtect } from "./utils/ProtectRoutes/AuthProtect/AuthProtect";
import LandingPage from "./pages/LandingPange/LandingPage";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import AdminProfile from "./pages/user/AdminProfile/AdminProfile";
import ForgetPassword from "./pages/user/AdminProfile/ForgetPassword";
import DeleteAccount from "./pages/user/AdminProfile/DeleteAccount";
import JoinRoom from "./pages/JoinRoom/JoinRoom";
import JoinRoomLayout from "./layouts/joinRoom/JoinRoomLayout";
import PublicRecordes from "./pages/publicRecordes/PublicRecordes";
import OnlyJoin from "./pages/JoinRoom/OnlyJoin";
import AdminPanel from "./pages/admin-organization/Admin/AdminPanel";
// import Pricing from "./pages/Pricing/Pricing";
import Monitor from "./pages/admin-organization/Monitor/Monitor";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";
import ForgetPasswordUser from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import SubscriptionPlans from "./pages/admin-organization/Subscriptionplans/Subscriptionplans";
import Checkout from "./pages/checkout/Checkout";
import MyMeeting from "./pages/user/MyMeeting/MyMeeting";
// import Features from "./pages/Features/Features";
import Feedback from "./pages/Feedback/Feedback";
import MyScbscription from "./pages/user/My Subscription/MyScbscription";
import MyTransaction from "./pages/user/My Transaction/MyTransaction";
import ContactUs from "./pages/ContactUs/ContactUs";
import TermsConditions from "./pages/TemsConditions/TermsCondtions";
import Privacy from "./pages/Privcy/Privacy";
import AllTransaction from "./pages/admin-organization/AllTransaction/AllTransaction";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import CancelPage from "./pages/CancelPage/CancelPage";
import MainLayout from "./layouts/MainLayout.js/MainLayout";
import ModeratorLayout from "./layouts/moderator/ModeratorLayout";
//new pages
import NewHome from "./pages/Home";
import Services from "./pages/services/Services";
import Students from "./pages/services/Students";
import School from "./pages/services/School";
import OnlineClass from "./pages/online_classes/Online_classes";
import FeaturesPage from "./pages/online_classes/FeaturesPage";
import Pricing from "./pages/online_classes/Pricing";
import Contact from "./pages/Contact";
import About from "./pages/About";
import LayoutMain from "./layouts/MainLayout.js/LayoutMain";
import FAQ from "./pages/FAQ/Faq";
import Blog from "./pages/Blog/Blog";
import Teachers from "./pages/services/Teachers";
import PricingPolicy from "./pages/Privcy/PricingPolicy";
import CancellationRefund from "./pages/Privcy/CancellationRefund";
import BlogLayout from "./pages/Blog/BlogLayout";
import BlogList from "./pages/Blog/BlogList";
export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <LayoutMain />,
      children: [
        {
          path: "/",
          element: <NewHome />,
        },
        {
          path: "lms",
          element: <Services />,
        },
        {
          path: "students",
          element: <Students />,
        },
        {
          path: "school",
          element: <School />,
        },
        {
          path: "teachers",
          element: <Teachers />,
        },
        {
          path: "online-classes",
          element: <OnlineClass />,
        },
        {
          path: "features",
          element: <FeaturesPage />,
        },
        {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "checkout/:id",
          element: <Checkout />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "faq",
          element: <FAQ />,
        },
        {
          path: "privacy-policy",
          element: <Privacy />,
        },
        {
          path: "pricing-policy",
          element: <PricingPolicy />,
        },
        {
          path: "cancellation-refund-policy",
          element: <CancellationRefund />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsConditions />,
        },
        // {
        //   path: "blog",
        //   element: <Blog />,
        // },
        // {
        //   path: "blog/:name",
        //   element: <Blog />,
        // },
        {
          path: "blog",
          element: <BlogLayout />, // Separate layout for blog pages
          children: [
            {
              path: "/blog",
              element: <BlogList />,
            },
            {
              path: ":name",
              element: <Blog />,
            },
          ],
        },
      ],
    },

    {
      path: "login",
      element: <SignIn />,
    },

    {
      path: "signup",
      element: <SignUp />,
    },

    {
      path: "room/:id/public_recordings",
      element: (
        <JoinRoomLayout>
          <PublicRecordes />
        </JoinRoomLayout>
      ),
    },

    {
      path: "room/:id/join",
      element: <JoinRoom />,
    },

    {
      path: ":id/verifyEmail",
      element: <VerifyEmail />,
    },
    {
      path: "forgetpassword",
      element: <ForgetPasswordUser />,
    },
    {
      path: ":id/resetPassword",
      element: <ResetPassword />,
    },

    {
      path: "success",
      element: <SuccessPage />,
    },
    {
      path: "cancel",
      element: <CancelPage />,
    },
    { path: "404", element: <Page404 /> },
    {
      path: "/homes",
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/homes" />, index: true },
        {
          path: "homes",
          element: <LandingPage />,
        },
        {
          path: "contactus",
          element: <ContactUs />,
        },
        {
          path: "termsconditions",
          element: <TermsConditions />,
        },

        // {
        //   path: "pricing",
        //   element: <Pricing />,
        // },

        // {
        //   path: "features",
        //   element: <Features />,
        // },
      ],
    },

    {
      element: <ModeratorLayout />,
      children: [
        {
          path: "room",
          element: (
            <AuthProtect>
              <Moderator />
            </AuthProtect>
          ),
        },
        {
          path: "feedback",
          element: <Feedback />,
        },
        {
          path: "room/:id",
          element: (
            <AuthProtect>
              <SingleRoom />
            </AuthProtect>
          ),
        },
        {
          path: "recordings",
          element: (
            <AuthProtect>
              <RecordingPage />
            </AuthProtect>
          ),
        },
        {
          path: "only_join",
          element: (
            <AuthProtect>
              <OnlyJoin />
            </AuthProtect>
          ),
        },
      ],
    },

    {
      path: "/settings",
      element: (
        <AuthProtect>
          <ProfileDashboardLayout />
        </AuthProtect>
      ),
      children: [
        { element: <Navigate to="/settings/profile" />, index: true },
        { path: "profile", element: <AdminProfile /> },
        { path: "forgetPassword", element: <ForgetPassword /> },
        { path: "deleteAccount", element: <DeleteAccount /> },
        { path: "mymeeting", element: <MyMeeting /> },
        { path: "mysubscription", element: <MyScbscription /> },
        { path: "mytransaction", element: <MyTransaction /> },
      ],
    },
    {
      path: "/organization",
      element: (
        <AuthProtect>
          <OrganizationLayout />
        </AuthProtect>
      ),
      children: [
        { element: <Navigate to="/organization/admin" />, index: true },
        { path: "admin", element: <AdminPanel /> },
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "users", element: <MangeUsers /> },
        { path: "server_rooms", element: <ServerRoom /> },
        { path: "server_recordings", element: <ServerRecordings /> },
        { path: "site_settings", element: <SiteSettings /> },
        { path: "room_configuration", element: <RoomConfiguration /> },
        { path: "roles", element: <Role /> },
        { path: "monitor", element: <Monitor /> },
        { path: "subscription_plans", element: <SubscriptionPlans /> },
        { path: "alltransaction", element: <AllTransaction /> },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/" />, index: true },

    //     { path: "*", element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
