import diverseparticipantsIcon from "src/assets/images/online-classes/featuresIcon/diverseparticipants.svg";
import storagebenifits from "src/assets/images/online-classes/featuresIcon/storagebenifits.svg";
import ScreenSharing from "src/assets/images/online-classes/featuresIcon/ScreenSharing.svg";
import recording from "src/assets/images/online-classes/featuresIcon/recording.svg";
import subscriptionbenifit from "src/assets/images/online-classes/featuresIcon/subscriptionbenifit.svg";
import breakoutroom from "src/assets/images/online-classes/featuresIcon/breakoutroom.svg";
import polling from "src/assets/images/online-classes/featuresIcon/polling.svg";
import duration from "src/assets/images/online-classes/featuresIcon/duration.svg";
import chatfeatureIcon from "src/assets/images/online-classes/featuresIcon/chatfeatureIcon.svg";
import Multiuserdashboard from "src/assets/images/online-classes/featuresIcon/Multiuserdashboard.svg";
import giveaccess from "src/assets/images/online-classes/featuresIcon/giveaccess.svg";
import CreateRoom from "src/assets/images/online-classes/featuresIcon/CreateRoom.svg";
import DiverseParticipantsIcon from "src/assets/images/online-classes/icons/Diverse Participants.svg";
import StoragebenefitsIcon from "src/assets/images/online-classes/icons/Storage benefits.svg";
import RecordingsIcon from "src/assets/images/online-classes/icons/Recordings.svg";
import DurationIcon from "src/assets/images/online-classes/icons/Duration.svg";
import ChatfeatureIcon from "src/assets/images/online-classes/icons/Chat feature.svg";
import ScreenSharingIcon from "src/assets/images/online-classes/icons/Screen Sharing.svg";
import MultiuserdashboardIcon from "src/assets/images/online-classes/icons/Multi-user dashboard.svg";
import SubscriptionbenefitsIcon from "src/assets/images/online-classes/icons/Subscription benefits.svg";
import ShareroomaccessIcon from "src/assets/images/online-classes/icons/Share room access.svg";
import BreakoutRoomsIcon from "src/assets/images/online-classes/icons/Breakout Rooms.svg";
import SharedNotesIcon from "src/assets/images/online-classes/icons/Shared Notes.svg";
import ShareRecordingsIcon from "src/assets/images/online-classes/icons/Share Recordings.svg";




export const featuresContent = [
  {
    icon: diverseparticipantsIcon,
    title: "Diverse Participants",
    description:
      "The meeting room can accommodate numerous participants, and the moderator who has subscribed to specific plans will reap the advantage of an extended meeting duration.",
  },
  {
    icon: storagebenifits,
    title: "Storage benefits",
    description:
      "Moderators will have the advantage of utilizing storage benefits, enabling them to securely store files, recordings, and other relevant data",
  },
  {
    icon: recording,
    title: "Recordings",
    description:
      "The user can record their meeting room and can conveniently access it at any given time.",
  },
  {
    icon: duration,
    title: "Duration",
    description:
      "Users are allotted meeting durations based on their subscription, allowing them to schedule and record meetings accordingly.",
  },
  {
    icon: chatfeatureIcon,
    title: "Chat feature",
    description:
      "The participants in the meeting can utilize the Public / Private Chat feature to communicate or engage in conversation.",
  },
  {
    icon: ScreenSharing,
    title: "Screen Sharing",
    description:
      "Screen sharing allows the moderator to display their screen content to participants in a virtual meeting.",
  },
  {
    icon: Multiuserdashboard,
    title: "Multi-user dashboard",
    description:
      "The multi-user dashboard on the AtLearn website enables multiple users to collaboratively access and manage educational resources, track progress, and participate in courses.",
  },
  {
    icon: subscriptionbenifit,
    title: "Subscription benefits",
    description:
      "The user can avail the advantage as per their subscription plan, along with a trial period of 14 days, depending on the subscription chosen",
  },
  {
    icon: giveaccess,
    title: "Share room access",
    description:
      "Share room access enables users to grant access to their rooms to other moderators and users, facilitating the creation or scheduling of shared rooms.",
  },
  {
    icon: breakoutroom,
    title: "Breakout Rooms",
    description:
      "The moderator can utilize the Screen Sharing Multi-User Whiteboard during their presentation, enabling them to grant access to any participant for collaborative use.",
  },
  {
    icon: polling,
    title: "Shared Notes",
    description:
      "Moderators can utilize breakout rooms to establish smaller internal meetings or discussions before the main meeting.",
  },
  {
    icon: CreateRoom,
    title: "Share Recordings on Social Media",
    description:
      "The feature allows users to easily post meeting recordings to social media platforms directly from the online meeting interface.",
  },
];

export const featuresMenuItems = [
  {
    label: "Diverse Participants",
    icon: DiverseParticipantsIcon,
    url: "/features#features",
  },
  {
    label: "Storage benefits",
    icon: StoragebenefitsIcon,
    url: "/features#features",
  },
  { label: "Recordings", icon: RecordingsIcon, url: "/features#features" },
  { label: "Duration", icon: DurationIcon, url: "/features#features" },
  { label: "Chat feature", icon: ChatfeatureIcon, url: "/features#features" },
  { label: "Screen Sharing", icon: ScreenSharingIcon, url: "/features#features" },
  {
    label: "Multi-user dashboard",
    icon: MultiuserdashboardIcon,
    url: "/features#features",
  },
  {
    label: "Subscription benefits",
    icon: SubscriptionbenefitsIcon,
    url: "/features#features",
  },
  { label: "Share room access", icon: ShareroomaccessIcon, url: "/features#features" },
  { label: "Breakout Rooms", icon: BreakoutRoomsIcon, url: "/features#features" },
  { label: "Shared Notes", icon: SharedNotesIcon, url: "/features#features" },
  {
    label: "Share Recordings on Social Media",
    icon: ShareRecordingsIcon,
    url: "/features#features",
  },
];
