import Header from "../../components/Header";
import InfoSection from "../../components/InfoSection";
import Features from "../../components/Features";
import Button from "../../components/Button";
import backgroundcta from "../../assets/img/backgroundd-cta1.webp";
import Footer from "src/components/Footer";
import { useNavigate } from "react-router-dom";
import {
  experienceContent,
  supportConetent,
  featuresContent,
} from "src/Data/onlineClassesPageData";
import PublicMeetings from "src/components/PublicMeetings/PublicMeetings";
function Onlineclasses() {
  const navigate = useNavigate();

  return (
    <div>
      <Header
        backgroundImage={"/cover_images/online_class.svg"}
        // backgroundImage={"/cover_images/EmployeeTrainingNeeds.png"}
        pageTitle={"Online Classes"}
        subTitle={
          "Learn anytime, anywhere—unlock your potential with Atlearn's online classes"
        }
        cta={"Get Started Now"}
      />
      <div className="mt-2" >
        <InfoSection info={experienceContent} />
      </div>

      <div >
        <InfoSection info={supportConetent} imageSide="left" />
      </div>
      <div className="mb-8">
        <Features features={featuresContent} />
      </div>
      <PublicMeetings />
      <div>
        <section
          className="text-neutral-700 bg-no-repeat bg-cover py-28"
          id="section-1"
          style={{
            backgroundPosition: "50% 50%",
            backgroundImage: `url(${backgroundcta})`,
          }}
        >
          <div className="flex m-auto">
            <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
              <div className="content-start flex-wrap flex w-full">
                <section className="w-full">
                  <div className="flex max-w-[82.50rem] m-auto" id="div-2">
                    <div className="flex min-h-[0.06rem] md:w-full" id="div-3">
                      <div className="content-start flex-wrap px-4 flex w-full">
                        <div
                          className="flex-col justify-center w-full"
                          style={{
                            alignItems: "initial",
                          }}
                        >
                          <div>
                            <div
                              className="bg-fuchsia-950 md:rounded-bl-full md:rounded-br-full md:rounded-tl-full md:rounded-tr-3xl rounded-xl sm:p-20 p-10 max-w-[38.13rem]"
                              id="div-4"
                            >
                              <h2 className="text-white text-[2.50rem] leading-none font-semibold -mt-1 mb-5 font-fredoka">
                                Start your free 14 days trial
                              </h2>

                              <p className="text-zinc-300 text-lg mb-8 font-fredoka">
                                Protect your meetings with end-to-end
                                encryption. Delight in remarkable video clarity.
                              </p>

                              <Button
                                onClick={() => navigate("/login")}
                                text={"Login Now"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Onlineclasses;
