import React, { useEffect, useState } from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";
import videoCallIcon from "../../assets/img/circlevideo.svg";
import apiClients from "src/apiClients/apiClients";
import { blogContent } from "src/Data/blogData";
import { Outlet, useNavigate } from "react-router-dom";
import { setSearchQuery } from "src/Redux/searchBlogSlice";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
function BlogLayout() {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);
  const [liveRooms, setLiveRooms] = useState();
  const dispatch = useDispatch();
  const { searchQuery, searchResults, loading, error } = useSelector(
    (state) => state.searchBlog
  );
  useEffect(() => {
    fetchLiveRooms();
  }, []);

  const fetchLiveRooms = async () => {
    try {
      const response = await apiClients.liveRoom();
      if (response?.data?.filtered) {
        setLiveRooms(response.data.filtered);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopy = (id) => {
    window.open(`${window.location.origin}/room/${id}/join`);
  };

  //   const handleButtonClick = () => {
  //     setShowVideo(true);
  //   };

  const handleCloseClick = () => {
    setShowVideo(false);
  };
  return (
    <div>
      <div className="text-neutral-700 overflow-x-hidden">
        <Header
          backgroundImage={"/cover_images/blogs.svg"}
          pageTitle={"Blogs"}
          subTitle={
            "Discover Insights and Trends Shaping the Future of Learning and Education"
          }
          cta={"Explore Now"}
        />
        <section className="pb-[calc(120px_-_30px)] pt-28" id="section-1">
          <div className="w-full m-auto min-[576px]:max-w-[33.75rem]  md:max-w-[45.00rem] min-[992px]:max-w-[60.00rem] min-[1200px]:max-w-[71.25rem] min-[1400px]:max-w-[82.50rem] min-[1300px]:max-w-[calc(1290px_+_30px)] min-[1300px]:pl-[calc(30px_/_2)] min-[1300px]:pr-[calc(30px_/_2)]">
            {/* <div className="flex items-center justify-center min-h-64 ">
              <h1 className="text-2xl font-bold text-gray-800 p-2">
                No new blog posts available. Please check back soon!
              </h1>
            </div> */}

            <div className="flex-wrap flex mt-[calc(15px_*_-1)] mx-[calc(20px/_">
              <div className="px-5 w-full max-w-full mt-3.5 min-[992px]:w-2/3">
                <div className="flex-wrap flex mt-[calc(15px_*_-1)] mx-[calc(20px/_">
                  <Outlet />
                </div>
              </div>

              <div className="px-5 w-full max-w-full mt-3.5 min-[992px]:w-1/3">
                <div id="div-23">
                  <div
                    className="bg-slate-100 mb-10 rounded-3xl p-8"
                    id="div-24"
                  >
                    <form className="flex-wrap flex">
                      <label className="text-black text-3xl font-semibold pb-3.5 w-full -mt-1 mb-8">
                        Search
                      </label>
                      <div className="flex-grow flex max-w-full">
                        <input
                          className="bg-white rounded-bl-2xl rounded-tl-2xl cursor-text flex-grow py-2 pl-5 pr-2 w-full h-12 min-w-[3.00rem] max-w-[calc(100%_-_110px)] border-2 border-black/[0.1] border-solid"
                          value={searchQuery}
                          onChange={(e) => dispatch(setSearchQuery(e.target.value))}
                          id="input-1"
                          placeholder=""
                          style={{
                            outlineOffset: "-2px",
                          }}
                          type="search"
                        />
                        <button className="text-white bg-fuchsia-950 items-start rounded-br-2xl rounded-tr-2xl cursor-pointer text-lg font-medium py-1.5 px-2 text-center w-28 h-12">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>

                  <div
                    className="bg-slate-100 relative mb-10 rounded-3xl p-8"
                    id="div-40"
                  >
                    <h3 className="text-black text-3xl font-semibold pb-3.5 -mt-1 ">
                      Recent Posts
                    </h3>
                    <div>
                      {blogContent?.map((item, index) => (
                        <p
                          onClick={() => navigate(`/blog/${item?.heading}`)}
                          className="text-lg font-normal mb-4 hover:text-red-500 cursor-pointer"
                        >
                          {item?.heading}
                        </p>
                      ))}
                    </div>
                  </div>

                  {liveRooms?.length > 0 && (
                    <div
                      className="bg-slate-100 mb-10 rounded-3xl font-semibold p-8"
                      id="div-39"
                    >
                      <h3 className="text-black text-3xl pb-3.5 -mt-1 mb-8">
                        Live Rooms
                      </h3>
                      {liveRooms?.map((item, index) => (
                        <div key={index}>
                          <div
                            onClick={() => handleCopy(item.friendly_id)}
                            className="items-center flex mb-5 "
                          >
                            <div
                              className=" cursor-pointer text-white  flex-col text-3xl justify-center text-center uppercase flex w-20 h-24 mr-5 rounded-3xl"
                              id="a-17"
                            >
                              <img
                                alt=""
                                className="align-middle inline-block w-24 h-auto max-w-full"
                                src={videoCallIcon}
                              />
                            </div>
                            <div className="flex-grow text-[1.38rem] leading-7 text-black">
                              <h4>{item.name}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* <div
                    className="bg-slate-100 relative mb-10 rounded-3xl p-8"
                    id="div-40"
                  >
                    <h3 className="text-black text-3xl font-semibold pb-3.5 -mt-1 mb-8">
                      Video
                    </h3>
                    <div>
                      <div
                        onClick={handleButtonClick}
                        className="relative mb-3.5 rounded-2xl overflow-hidden cursor-pointer "
                      >
                        <img
                          alt=""
                          className="w-full h-auto max-w-full"
                          src={IntroVideoImg}
                        />

                        <a
                          className="text-rose-600 left-1/2 absolute top-1/2 z-[1] inline-block"
                          href="https://www.youtube.com/watch?v=RbPzfE0wEqQ"
                        >
                          <i className="bg-white cursor-pointer inline-block rounded-full" />
                        </a>
                      </div>

                      <h4 className="text-black text-[1.63rem] leading-8 font-semibold -mb-1">
                        A very warm welcome to our new Treasurer
                      </h4>
                    </div>
                  </div> */}

                  <div>
                    {showVideo && (
                      <div className="overlay">
                        <div className="video-container">
                          <div className="video-wrapper">
                            <iframe
                              width="100%"
                              height="415"
                              src="https://www.youtube.com/embed/mRYlSrIbw0s"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                          <button
                            className="close-button"
                            onClick={handleCloseClick}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div
                    className=' p-8 bg-fuchsia-950 bg-[url("https://wordpress.vecurosoft.com/kiddino/wp-content/uploads/2023/09/widget-bg-1-1.png")] bg-no-repeat bg-cover mb-10 rounded-3xl text-white'
                    id="div-41"
                    style={{
                      backgroundPosition: "50% 50%",
                    }}
                  >
                    {" "}
                    <h4 className="text-3xl font-semibold -mt-2 mb-3.5">
                      Join together to make amazing things happen
                    </h4>
                    <div>
                      <p className="pb-1 mb-5">
                        Get all the latest information, support and guidance
                        about the cost of living with kindergarten.
                      </p>
                      <a
                        className="bg-rose-600 font-semibold py-5 px-10 text-center capitalize align-middle inline-block rounded-full overflow-hidden"
                        href="/login"
                        id="a-20"
                      >
                        Start Registration
                      </a>
                    </div>
                    {"    "}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <section />
      </div>
    </div>
  );
}

export default BlogLayout;
