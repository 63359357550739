import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { blogContent } from "src/Data/blogData";

function Blog() {
  const { name } = useParams();
  const { searchQuery } = useSelector((state) => state.searchBlog);
  const matchingSection = blogContent.find(
    (section) =>
      (searchQuery || name) &&
      section.heading
        .toLowerCase()
        .includes((searchQuery || name).toLowerCase())
  );

 

  return (
    <div>
      {matchingSection ? (
        <div>
          <div className="bg-slate-100 rounded-tl-3xl rounded-tr-3xl -mt-1 -mr-1 -ml-1 overflow-hidden text-rose-600">
            <img
              alt=""
              className="cursor-pointer   w-full object-container "
              src={matchingSection?.image}
            />
          </div>
          <div className="mb-1 text-rose-600 font-medium">
            <span className="inline-block mr-5" id="span-9">
              <a
                className="inline-block"
                href="https://atlearn.in/2023/08/09/"
                id="a-14"
              >
                <i className="cursor-pointer inline-block mr-1.5" />
                <div className="cursor-pointer">{matchingSection?.date}</div>
              </a>
            </span>
            <span className="inline-block" id="span-10">
              <a
                className="inline-block"
                href="https://atlearn.in/a-very-warm-welcome-to-our-new-treasurer/#respond"
                id="a-15"
              >
                <i className="cursor-pointer inline-block mr-1.5" />0 Comments
              </a>
            </span>
          </div>
          <div>
            <h2 className="text-black text-[2.88rem] leading-none font-semibold capitalize mb-3.5">
              {matchingSection.heading}
            </h2>

            {matchingSection.paragraphs?.map((paragraph, pIndex) => (
              <p
                key={pIndex}
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: paragraph,
                }}
              />
            ))}

            {matchingSection.subSections?.map((subSection, subIndex) => (
              <div key={subIndex}>
                <h3 className="text-3xl font-semibold text-black mb-6">
                  {subSection.subHeading}
                </h3>

                {subSection.paragraphs?.map((subParagraph, spIndex) => (
                  <p
                    key={spIndex}
                    className="mb-6"
                    dangerouslySetInnerHTML={{
                      __html: subParagraph,
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-64 ">
          <h1 className="text-2xl font-bold text-gray-800 p-2">
            No matching content found.
          </h1>
        </div>
      )}
    </div>
  );
}

export default Blog;
