import React, { useEffect, useState } from "react";
// import { CgSearch } from 'react-icons/cg';
import {
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaEnvelope,
  FaMinus,
  FaMobileAlt,
  FaPlus,
  FaUserGraduate,
  FaSchool,
  FaChalkboardTeacher,
  FaCogs,
  FaCode,
  // FaRegCalendarAlt,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import AccountPopover from "../dashboard/header/AccountPopover";
import { LiaTimesSolid } from "react-icons/lia";
import { PopupModal } from "react-calendly";
import courseManagement1 from "../../assets/images/Blogs/image1.webp";
import courseManagement2 from "../../assets/images/Blogs/image2.webp";
import courseManagement3 from "../../assets/images/Blogs/image3.webp";
import { setUser } from "src/Redux/userSlice";
import { studentMenuItems } from "src/Data/studentPageData";
import { schoolMenuItems } from "src/Data/schoolPageData";
import { teacherMenuItems } from "src/Data/teachersData";
import { featuresMenuItems } from "src/Data/featuresPageData";
const APIURL = window.location.origin;
function LayoutMain() {
  const navItems = [
    {
      title: "Home",
      link: "/",
      children: [],
    },
    {
      title: "About",
      link: "/about",
      children: [],
    },
    {
      title: "LMS",
      link: "/lms",
      children: [
        { title: "Teachers", link: "/teachers" },
        { title: "Students", link: "/students" },
        { title: "School", link: "/school" },
      ],
    },
    {
      title: "Online Classes",
      link: "/online-classes",
      children: [
        { title: "Features", link: "/features" },
        // { title: "Pricing", link: "/pricing" },
        { title: "API", link: `${APIURL}/api/docs/` },
      ],
    },

    {
      title: "Pricing",
      link: "/pricing",
      children: [],
    },
    {
      title: "Contact",
      link: "/contact",
      children: [],
    },
    {
      title: "Login",
      link: "/login",
      children: [],
    },
    {
      title: "Logout",
      link: "/",
      children: [],
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const { user } = useSelector((state) => state.user);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(false);
  const [showLeftSideBar, setShowLeftSideBar] = useState(false);
  const dispatch = useDispatch();

  const [dropdownLMS, setDropdownLMS] = useState(false);
  const [dropdownClasses, setDropdownClasses] = useState(false);
  const [submenuStudents, setSubmenuStudents] = useState(false);
  const [submenuSchool, setSubmenuSchool] = useState(false);
  const [submenuTeachers, setSubmenuTeachers] = useState(false);
  const [submenuFeatures, setSubmenuFeatures] = useState(false);

  useEffect(() => {
    if (user?.user) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [user]);

  const toggleSection = (title) => {
    setOpenSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  const handleLogout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    dispatch(setUser(""));

    // await keycloak.logout();
    setShowLeftSideBar(false);
    navigate("/login", { replace: true });
  };
  return (
    <div>
      <PopupModal
        url="https://calendly.com/atlearn-lms/30min"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
      <div className="py-3 px-28 bg-primary text-white flex justify-center lg:justify-between items-center ">
        <div className="gap-4 items-center hidden lg:flex"></div>

        <div className="flex gap-4 md:gap-8 flex-wrap  justify-center">
          <div className="flex gap-4 items-center group">
            <Button onClick={() => setIsOpen(true)}>Request Demo</Button>
          </div>
          <div className="flex gap-4 items-center group">
            <div className="w-10 h-10 bg-transparent group-hover:bg-secondary group-hover:ring-secondary duration-300 rounded-full ring-1 ring-white flex justify-center items-center">
              <FaEnvelope size={17} color="white" />
            </div>

            <a
              href="mailto:contact@atlearn.in"
              className="hover:text-secondary duration-300 font-fredoka"
            >
              support@atlearn.in
            </a>
          </div>

          <div className="flex gap-4 items-center group">
            <div className="w-10 h-10 bg-transparent group-hover:bg-secondary group-hover:ring-secondary duration-300 rounded-full ring-1 ring-white flex justify-center items-center">
              <FaMobileAlt size={17} color="white" />
            </div>

            <a
              href="tel:8015801639"
              className="hover:text-secondary duration-300 font-fredoka"
            >
              +91 8015801639
            </a>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="px-6  justify-between items-center flex"
      >
        <div className="mt-1 mb-1  block lg:hidden">
          <Logo />
        </div>
        <div className="lg:flex justify-between items-center hidden lg:block gap-12">
          <div className="mt-1 mb-1  ">
            <Logo />
          </div>
          <div className="flex gap-10">
            <Link
              to={"/"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Home
            </Link>
            <Link
              to={"/about"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              About
            </Link>

            <div
              className="relative"
              onMouseEnter={() => setDropdownLMS(true)}
              onMouseLeave={() => {
                setDropdownLMS(false);
                setSubmenuStudents(false); // Close submenu on leave
              }}
            >
              <Link to={"/lms"}>
                <button className="hover:text-secondary focus:outline-none font-semibold text-lg font-fredoka py-[38px] flex items-center gap-1">
                  LMS <FaChevronDown size={13} />
                </button>
              </Link>

              {dropdownLMS && (
                <div className="absolute left-0  py-2 w-48 font-fredoka font-semibold bg-white rounded-md shadow-xl z-20 transition-opacity duration-300">
                  <div
                    className="px-4 py-2 hover:bg-gray-200 flex items-center relative"
                    onMouseEnter={() => setSubmenuStudents(true)}
                    onMouseLeave={() => setSubmenuStudents(false)}
                  >
                    <Link
                      to={"/students"}
                      className=" py-2 hover:bg-gray-200 flex items-center"
                    >
                      <FaUserGraduate className="mr-2 text-secondary" />
                      <span>Students</span>
                    </Link>
                    {submenuStudents && (
                      <div className="absolute left-full top-0 ml-0.5 p-2 min-w-[36vw] bg-white rounded-md shadow-xl z-30 transition-opacity duration-300 grid grid-cols-2 gap-2">
                        {studentMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.url}
                            className="px-4 py-2 hover:bg-gray-200 flex items-center"
                          >
                            {item.icon ? (
                              <img
                                src={item.icon}
                                alt={`${item.label} Icon`}
                                className="h-4 w-4 object-contain mr-2"
                              />
                            ) : (
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                            )}
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="px-4 py-2 hover:bg-gray-200 flex items-center relative"
                    onMouseEnter={() => setSubmenuSchool(true)}
                    onMouseLeave={() => setSubmenuSchool(false)}
                  >
                    <Link
                      to={"/school"}
                      className=" py-2 hover:bg-gray-200 flex items-center"
                    >
                      <FaSchool className="mr-2 text-secondary" />
                      <span>School</span>
                    </Link>
                    {submenuSchool && (
                      <div className="absolute left-full top-0 ml-0.5 p-2 min-w-[36vw] bg-white rounded-md shadow-xl z-30 transition-opacity duration-300 grid grid-cols-2 gap-2">
                        {schoolMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.url}
                            className="px-4 py-2 hover:bg-gray-200 flex items-center"
                          >
                            {item.icon ? (
                              <img
                                src={item.icon}
                                alt={`${item.label} Icon`}
                                className="h-4 w-4 object-contain mr-2"
                              />
                            ) : (
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                            )}
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="px-4 py-2 hover:bg-gray-200 flex items-center relative"
                    onMouseEnter={() => setSubmenuTeachers(true)}
                    onMouseLeave={() => setSubmenuTeachers(false)}
                  >
                    <Link
                      to={"/teachers"}
                      className=" py-2 hover:bg-gray-200 flex items-center"
                    >
                      <FaChalkboardTeacher className="mr-2 text-secondary" />
                      <span>Teachers</span>
                    </Link>
                    {submenuTeachers && (
                      <div className="absolute left-full top-0 ml-0.5 p-2 min-w-[35vw] bg-white rounded-md shadow-xl z-30 transition-opacity duration-300 grid grid-cols-2 gap-2">
                        {teacherMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.url}
                            className="px-4 py-2 hover:bg-gray-200 flex items-center"
                          >
                            {item.icon ? (
                              <img
                                src={item.icon}
                                alt={`${item.label} Icon`}
                                className="h-4 w-4 object-contain mr-2"
                              />
                            ) : (
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                            )}
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="relative"
              onMouseEnter={() => setDropdownClasses(true)}
              onMouseLeave={() => setDropdownClasses(false)}
            >
              <Link to={"/online-classes"}>
                <button className="hover:text-secondary focus:outline-none font-semibold text-lg font-fredoka py-[38px] flex items-center gap-1">
                  Online Classes <FaChevronDown size={13} />
                </button>
              </Link>

              {dropdownClasses && (
                <div className="absolute left-0  py-2 w-48 font-fredoka font-semibold bg-white rounded-md shadow-xl z-20 transition-opacity duration-300">
                  <div
                    className="px-4 py-2 hover:bg-gray-200 flex items-center relative"
                    onMouseEnter={() => setSubmenuFeatures(true)}
                    onMouseLeave={() => setSubmenuFeatures(false)}
                  >
                    <Link
                      to="/features"
                      className=" py-2 hover:bg-gray-200 flex items-center"
                    >
                      <FaCogs className="mr-2 text-secondary" />{" "}
                      {/* Features Icon */}
                      <span>Features</span>
                    </Link>
                    {submenuFeatures && (
                      <div className="absolute left-full top-0 ml-0.5 p-2 min-w-[35vw] bg-white rounded-md shadow-xl z-30 transition-opacity duration-300 grid grid-cols-2 gap-2">
                        {featuresMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.url}
                            className="px-4 py-2 hover:bg-gray-200 flex items-center"
                          >
                            {item.icon ? (
                              <img
                                src={item.icon}
                                alt={`${item.label} Icon`}
                                className="h-4 w-4 object-contain mr-2"
                              />
                            ) : (
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                            )}
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>

                  <a
                    href={`${APIURL}/api/docs/`}
                    className="px-4 py-2 hover:bg-gray-200 flex items-center"
                  >
                    <FaCode className="mr-2 text-secondary" /> {/* API Icon */}
                    <span>API</span>
                  </a>
                </div>
              )}
            </div>

            <Link
              to={"/pricing"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Pricing
            </Link>
            <Link
              to={"/contact"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Contact
            </Link>
          </div>
        </div>

        <div className="flex items-center gap-4 ">
          <FaBars
            size={25}
            className="cursor-pointer hidden lg:block"
            onClick={() => {
              setShowSideBar(true);
            }}
          />

          <div
            className="bg-secondary lg:hidden p-2 rounded-full flex justify-center items-center"
            onClick={() => {
              setShowLeftSideBar(true);
            }}
          >
            <FaBars size={25} color="white" className="cursor-pointer" />
          </div>

          <div>
            {active ? (
              <div
                className=" h-7"
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <AccountPopover />
              </div>
            ) : (
              <div className="mt-2 mb-2 md:mt-0 md:mb-0">
                <Button text={"Login"} onClick={() => navigate("/login")} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showSideBar ? (
        <div
          className={`bg-black bg-opacity-80 w-full h-screen  top-0 right-0 fixed z-50 ease-in-out duration-700 `}
        >
          <div className="bg-white min:w-96 w-1/4 h-screen float-right p-5 overflow-x-auto no-scrollbar">
            <div className="bg-primary rounded-full w-12 h-12 float-right flex justify-center items-center">
              <LiaTimesSolid
                color="white"
                size={25}
                className="cursor-pointer"
                onClick={() => {
                  setShowSideBar(false);
                }}
              />
            </div>
            <h2 className="text-[36px] font-fredoka font-semibold leading-none">
              Transforming Education Through Technology
            </h2>

            {/* <div className="flex gap-4 mt-4">
              <img src="/src/assets/icons/location.svg" alt="" />
              <span className="text-[18px] font-jost">
                Plot 88B, 2nd Avenue, Vettuvenkkeni, Chennai 600115, India
              </span>
            </div> */}

            <div className="flex flex-wrap justify-center items-center mt-4 gap-2 w-full">
              <img
                className="rounded-2xl w-32 h-32 border"
                src={courseManagement1}
                alt=""
              />
              <img
                className="rounded-2xl w-32 h-32 border"
                src={courseManagement2}
                alt=""
              />
              <img
                className="rounded-2xl w-32 h-32 border "
                src={courseManagement3}
                alt=""
              />
            </div>

            <h2 className="text-[30px] font-fredoka font-semibold leading-none mt-5">
              Get In Touch
            </h2>
            <div className="w-8 h-1 rounded-full bg-yellow-300 mt-1" />
            <div
              className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-5 mb-2  "
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex items-center font-fredoka">
                <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaEnvelope color="black" fill="black" />
                </div>
                support@atlearn.in
              </div>
            </div>

            <div
              className="flex-col justify-center w-full max-w-full text-lg"
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex items-center font-fredoka" id="li-3">
                <div className="bg-yellow-400 font-black h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaMobileAlt fill="black" />
                </div>
                +91 8015801639
              </div>
            </div>

            <div
              className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-2  "
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex  items-center font-fredoka">
                <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaLocationDot color="black" fill="black" />
                </div>
                <span className="w-72">
                  Plot 88B, 2nd Avenue, Vettuvenkkeni, Chennai 600115, India
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {showLeftSideBar ? (
        <div
          className={`bg-black bg-opacity-80 w-full h-screen fixed top-0 left-0 z-50 ease-in-out duration-700 `}
        >
          <div className="bg-white w-96 min-w-96 h-screen p-5 overflow-x-auto no-scrollbar">
            <div className="bg-secondary rounded-full w-12 h-12 float-right flex justify-center items-center">
              <LiaTimesSolid
                color="white"
                size={25}
                className="cursor-pointer"
                onClick={() => {
                  setShowLeftSideBar(false);
                }}
              />
            </div>
            <Logo />

            <ul className="space-y-4 mt-10">
              {navItems.map((item) =>
                item.title === "Login" && active ? null : item.title ===
                    "Logout" && !active ? null : (
                  <li key={item.title}>
                    <div className="flex justify-between items-center">
                      <Link
                        to={item.title === "Logout" ? "#" : item.link} // Set 'Logout' link to '#'
                        onClick={(e) => {
                          if (item.title === "Logout") {
                            e.preventDefault();

                            handleLogout();
                          } else {
                            setShowLeftSideBar(false);
                          }
                        }}
                      >
                        <button className="flex items-center text-lg font-semibold text-black">
                          {item.children.length > 0 ? (
                            openSections[item.title] ? (
                              <FaChevronDown className="mr-2" />
                            ) : (
                              <FaChevronRight className="mr-2" />
                            )
                          ) : (
                            <FaChevronRight className="mr-2 invisible" />
                          )}

                          {item.title}
                        </button>
                      </Link>
                      {item.children.length > 0 && (
                        <button
                          onClick={() => toggleSection(item.title)}
                          className={`ml-2 p-1 rounded-full ${
                            openSections[item.title]
                              ? "bg-secondary"
                              : "bg-primary"
                          }`}
                        >
                          {openSections[item.title] ? (
                            <FaMinus className="text-white" />
                          ) : (
                            <FaPlus className="text-white" />
                          )}
                        </button>
                      )}
                    </div>

                    {item.children.length > 0 && openSections[item.title] && (
                      <ul className="pl-8 mt-2 space-y-2">
                        {item.children.map((subItem) =>
                          subItem.title === "API" ? (
                            <a
                              key={subItem.title}
                              href={`${APIURL}/api/docs/`}
                              className="flex items-center text-gray-600 hover:text-blue-500 transition-colors"
                            >
                              <li className="flex items-center">
                                <FaChevronRight className="mr-2 text-xs text-gray-400" />
                                {subItem.title}
                              </li>
                            </a>
                          ) : (
                            <Link
                              key={subItem.title}
                              to={subItem.link}
                              onClick={() => {
                                setShowLeftSideBar(false);
                              }}
                              className="flex items-center text-gray-600 hover:text-blue-500 transition-colors"
                            >
                              <li className="flex items-center">
                                <FaChevronRight className="mr-2 text-xs text-gray-400" />
                                {subItem.title}
                              </li>
                            </Link>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                )
              )}
            </ul>

            <div className="mt-4">
              <h2 className="text-[30px] font-fredoka font-semibold leading-none mt-5">
                Get In Touch
              </h2>
              <div className="w-8 h-1 rounded-full bg-yellow-300 mt-1" />
              <div
                className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-5 mb-2  "
                style={{
                  alignItems: "initial",
                }}
              >
                <div className="flex items-center font-fredoka">
                  <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                    <FaEnvelope color="black" fill="black" />
                  </div>
                  support@atlearn.in
                </div>
              </div>

              <div
                className="flex-col justify-center w-full max-w-full text-lg"
                style={{
                  alignItems: "initial",
                }}
              >
                <div className="flex items-center font-fredoka" id="li-3">
                  <div className="bg-yellow-400 font-black h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                    <FaMobileAlt fill="black" />
                  </div>
                  +91 8015801639
                </div>
              </div>

              <div
                className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-2  "
                style={{
                  alignItems: "initial",
                }}
              >
                <div className="flex  items-center font-fredoka">
                  <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                    <FaLocationDot color="black" fill="black" />
                  </div>
                  <span className="w-72">
                    Plot 88B, 2nd Avenue, Vettuvenkkeni, Chennai 600115, India
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Outlet />
    </div>
  );
}

export default LayoutMain;
