import RightIcon from "../../assets/icons/SuccessIcon";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "src/Redux/userSlice";
import apiClients from "src/apiClients/apiClients";
import ContactForm from "src/components/ContactForm";
import Footer from "src/components/Footer";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
const Pricing = () => {
  const navigate = useNavigate();
  const auth = UseAuth(true);
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState();
  const sectionRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleScrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchData = async () => {
    try {
      const response = await await apiClients.pricing();
      if (response.data) {
        setPricing(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/pricing.svg"}
        pageTitle={"Pricing"}
        subTitle={
          "Affordable plans tailored for every learner—find the perfect fit with Atlearn"
        }
        cta={"Subscribe now"}
        handleScrollToSection={handleScrollToSection}
      />
      <div ref={sectionRef} className="mt-[50px]  flex flex-wrap">
        {pricing?.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              item?.name === "Free"
                ? navigate("/signup")
                : navigate(`/checkout/${item?.id}`);
            }}
            style={{ padding: "115px 72px 40px 72px", borderRadius: "30px" }}
            //       className=" relative box-border m-auto min-h-80 w-96
            // rounded-md  bg-[#F0F6FA] mt-20 "
            className=" relative box-border m-auto min-h-80 
      rounded-md  bg-[#F0F6FA] mt-20 transition-transform transform hover:scale-105 hover:cursor-pointer"
          >
            <div>
              <ul className="list-none mb-8 font-fredoka">
                <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.participants} participants
                </li>
                <li className="flex mb-4 gap-3" id="li-1">
                  <RightIcon /> {item.duration} hours
                </li>

                {/* <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.Validity}{" "}
                  {item?.period === "day"
                    ? item.Validity > 1
                      ? "days"
                      : "day"
                    : item.Validity > 1
                    ? "months"
                    : "month"}
                </li> */}
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.chat === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Public / Private Chat
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedNotes === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Shared Notes
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.screenshare === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Screen Sharing
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedRoomAccess === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Share Access
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.storage > 0 ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> {item.storage} GB
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.recording === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />{" "}
                  {item.recording === "true" ? "Recordings" : "No Recordings"}
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.breakout === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Breakout Rooms
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.multiuserwhiteboard === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Multi User Whiteboard
                </li>
                <h2 className="text-center text-xl font-fredoka font-medium mb-4 p-4 bg-blue-100 border border-blue-300 rounded-lg shadow-md">
                  LMS Features
                </h2>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.courseManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Course Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.efficientDigitalBookManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Efficient Digital Book Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.bulkEnrollment === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Bulk Enrollment
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.communicationTools === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Communication Tools
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.studentManagement === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Student Management
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.reportsAndAnalytics === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Reports And Analytics
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.customizationAndPersonalization === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Customization & Personalization
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.assessmentAndGrading === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Assessment & Grading
                </li>
                <h2 className="text-center text-xl font-fredoka font-medium mb-4 p-4 bg-blue-100 border border-blue-300 rounded-lg shadow-md">
                  AI Features
                </h2>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.multipleChoiceQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Multiple Choice Questions
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.rubricGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Rubric Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.studentWorkFeedback === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Student Work Feedback
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.professionalEmailCommunication === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Professional Email Communication
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.depthOfKnowledgeQuizGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Depth of Knowledge Quiz Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.careerOrCollegeCounselor === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Career or college counselor
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.ideaGenerator === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Idea Generator
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.learnCoding === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Learn coding
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.syllabus === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Syllabus
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.assessmentOutline === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Assessment Outline
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.lessonPlan5Es === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Lesson Plan - 5 E's
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.claimEvidenceReasoning === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Claim, Evidence, Reasoning
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.debate === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Debate
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.mockInterview === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Mock Interview
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.projectBasedLearning === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Project Based Learning
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.teamBasedActivity === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Team Based Activity
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.battleshipStyle === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Battleship Style
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.fillInTheBlankQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Fill In The Blank Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.scenarioBasedQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  Scenario-Based Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.trueFalseQuestions === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />
                  True/False Questions
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.timelyRelevantActionableFeedback === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Timely, relevant, and actionable feedback
                </li>
              </ul>
            </div>
            <Button
              disabled={auth?.user && item?.name === "Free"}
              text={item?.name === "Free" ? "Sign up for free" : "Subscribe"}
              onClick={() => {
                item?.name === "Free"
                  ? navigate("/signup")
                  : navigate(`/checkout/${item?.id}`);
              }}
            />

            <div
              style={{
                margin: "0 10px -50px 10px",
                padding: "25px 30px 22px 30px",
                width: "95%",
                border: "2px solid #490d59",
                borderRadius: "30PX",
                backgroundColor: "#ffffff",
              }}
              className="box-border   
        m4 absolute top-[-50px] left-0"
            >
              {item?.freelms === "true" && (
                <div
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "-15px",
                    backgroundColor: "#e8063c",
                    color: "#ffffff",
                    padding: "5px 10px",
                    borderRadius: "50px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  FREE LMS
                </div>
              )}

              <h1
                className="text-2xl font-fredoka font-semibold capitalize  "
                style={{ color: "#000", lineHeight: "1.2" }}
              >
                {item.name}
              </h1>
              <h1
                className="text-[40px] font-fredoka font-bold capitalize  "
                style={{ color: "#e8063c" }}
              >
                ₹ {item.price}
                <span
                  className="text-xl font-fredoka font-medium"
                  style={{ color: "#000", marginTop: "1px" }}
                >
                  / {item.Validity}{" "}
                  {item?.period === "day"
                    ? item.Validity > 1
                      ? "days"
                      : "day"
                    : item.Validity > 1
                    ? "months"
                    : "month"}
                </span>
              </h1>
            </div>
            {/* <div
              style={{
                margin: "0 10px -50px 10px",
                padding: "25px 30px 22px 30px",
                width: "95%",
                border: "2px solid #490d59",
                borderRadius: "30px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Added shadow for depth
              }}
              className="box-border m4 absolute top-[-50px] left-0 transition-transform transform hover:scale-105"
            >
              <h1
                className="text-2xl font-fredoka font-semibold capitalize mb-2" // Added margin-bottom for spacing
                style={{ color: "#000", lineHeight: "1.2" }}
              >
                {item.name}
              </h1>
              <h1
                className="text-[40px] font-fredoka font-bold capitalize text-gradient mb-1" // Added gradient
                style={{
                  background: "linear-gradient(90deg, #e8063c, #490d59)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                ₹ {item.price}
              </h1>
              <h2
                className="text-xl font-fredoka font-medium"
                style={{ color: "#333", marginTop: "5px" }} // Slightly darker color for contrast
              >
                {item.Validity}{" "}
                {item?.period === "day"
                  ? item.Validity > 1
                    ? "days"
                    : "day"
                  : item.Validity > 1
                  ? "months"
                  : "month"}
              </h2>
            </div> */}
          </div>
        ))}
      </div>
      <div className="mt-8">
        <ContactForm />
      </div>

      <Footer />
    </div>
  );
};

export default Pricing;
