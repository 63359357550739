import trackIcon from "src/assets/icons/performance.png";
import scheduleIcon from "src/assets/icons/calender.png";
import WhiteBordIcon from "src/assets/icons/whiteboard.png";
import Assessmentquiz from "src/assets/images/Services/Students/Assessment-quiz-12.webp";
import StudentDataManagement from "src/assets/images/Services/Students/EffectiveTools.gif";
import Timemanage from "src/assets/images/Services/Students/Time-manage1.webp";
import Libraryatlearn from "src/assets/images/Services/Students/Library-atlearn-41.webp";
import CourseDashboard from "src/assets/images/Services/Students/CourseDashboard.webp";
import ChapterTracking from "src/assets/images/Services/Students/ChapterTracking.webp";
import ProgressMarking from "src/assets/images/Services/Students/ProgressMarking.webp";
import searchengine from "src/assets/images/Services/searchengine.png";
import courseIcon from "src/assets/course.svg";
import AssessmentIcon from "src/assets/images/Services/Students/icons/Assessment And Quiz.svg"
import DatamangementIcon from "src/assets/images/Services/Students/icons/Data mangement.svg"
import CoursemanagementIcon from "src/assets/images/Services/Students/icons/Course management.svg"
import CustomizedlearningExperiencesIcon from "src/assets/images/Services/Students/icons/Customized learning Experiences.svg"
import FeaturesforstudentsIcon from "src/assets/images/Services/Students/icons/Features for students.svg"
import BookManagementIcon from "src/assets/images/Services/Students/icons/Book Management.svg"

export const CourseProgress = [
  {
    imageSrc: CourseDashboard,
    color: "bg-lime-600",
    iconSrc: searchengine,
    heading: "Course Dashboard",
    description:
      "Students can access a dedicated interface to view all the courses they are enrolled in, providing an organized and comprehensive overview of their learning journey.",
  },
  {
    imageSrc: ChapterTracking,
    color: "bg-cyan-500",
    iconSrc: searchengine,
    heading: "Comprehensive Lesson and Assignment Integration",
    description:
      "Add lessons, multiple assignments, and quizzes using the question bank directly on the course page.",
  },
  {
    imageSrc: ProgressMarking,
    color: "bg-fuchsia-950",
    iconSrc: searchengine,
    heading: "Diverse Activity Options",
    description:
      "Enhance your course by selecting from a variety of activities to keep learners engaged and interactive.",
  },
];

export const CourseProgressInfo = {
  title: "Course Progress",
  description:
    "The students can view all the courses they are taking in a different interface and can view all the chapters within the course. When a student completes a chapter they can mark that chapter as incomplete which will reflect in the course progress.",
};

export const courseManagementFeatures = [
  {
    id: 1,
    title: "Student Data Management",
    description:
      "Easily organize and access student information, including demographics, enrollment status, and academic records, ensuring streamlined administrative processes.",
  },
  {
    id: 2,
    title: "Assessment Management",
    description:
      "Create and administer assessments, including quizzes, tests, and assignments, with customizable options to suit your teaching objectives and track student progress effectively.",
  },
  {
    id: 3,
    title: "Attendance Tracking",
    description:
      "Monitor student attendance effortlessly, enabling you to identify patterns, address attendance issues promptly, and ensure accountability for student participation.",
  },
  {
    id: 4,
    title: "Performance Monitoring",
    description:
      "Gain insights into student performance through comprehensive analytics and reporting features, allowing you to track individual progress, identify areas for improvement, and provide targeted support as needed.",
  },
];

export const features = [
  {
    icon: WhiteBordIcon,
    title: "Teachers Availability",
    description:
      "View teachers' availability for efficient scheduling and coordination.",
  },
  {
    icon: scheduleIcon,
    title: "Schedule Interaction",
    description:
      "Plan interactions and appointments based on real-time availability.",
  },
  {
    icon: trackIcon,
    title: "Track Teacher Workload",
    description:
      "Manage teacher workload effectively with accessible scheduling information.",
  },
];

export const AssessmentAndQuiz = {
  title: "Assessment And Quiz",
  description:
    "Atlearn LMS quizzes help you understand your learning progress through exams, pre-tests, and feedback.",
  points: [
    "Create and resume polls, tests, and quizzes easily.",
    "Use visual materials and get instant feedback.",
    "Personalize certificates and organize quizzes into categories.",
    "Access a question bank and control the number of attempts.",
    "Customize your study materials and access courses conveniently.",
  ],
  image: Assessmentquiz,
};

export const EfficientStudent = {
  title: "Efficient Student Data Management At AtLearn",
  description:
    "The students can view all the courses they are taking in a different interface and can view all the chapters within the course. When a student completes a chapter they can mark that chapter as incomplete which will reflect in the course progress.",
  points: [
    "Group organization for shared or differentiated tasks.",
    "Easy access to student data for educators.",
    "Personalized instruction and support for optimal outcomes.",
    "Versatile enrollment options like manual, automated, self-enrollment.",
  ],
  image: StudentDataManagement,
};

export const FlexibleTime = {
  title: "Flexible Time Slot Available",
  description:
    "At AtLearn, students have the option to buy one or multiple time slots from various teachers. This level of flexibility enables students to customize their learning experiences according to their own schedules and preferences. It empowers students to delve into a wide range of subjects and teaching methods, thereby optimizing their learning potential.",
  points: [
    "Maximization of learning potential through flexibility",
    "Exploration of diverse subjects and teaching styles",
    "Personalized learning experiences based on individual schedules",
    "Access to multiple teachers' available time slots",
    "Flexible time slot purchases for students",
  ],
  image: Timemanage,
};

export const AtLearnExperience = {
  title: "Optimize Your AtLearn Experience With Its Features",
  description:
    "At AtLearn, transparency is of utmost importance. Our platform enables students and administrators to conveniently access teachers' availability, offering valuable information about their schedules. This feature empowers students to effectively plan their interactions and appointments, while administrators can efficiently handle teacher workload and scheduling. By fostering transparent communication and coordination, AtLearn significantly improves the teaching and learning experience for all parties involved.",
};

export const AtlearnBook = {
  title: "AtLearn - Book Management",
  description:
    "In Atlearn, our book-centric feature is designed to streamline the management and distribution of educational materials. It offers a centralized platform that empowers instructors to efficiently organize, upload, and administer digital books. Users have the flexibility to categorize books into folders, swiftly search for specific content, and navigate seamlessly using bookmarks and a table of contents. The inclusion of annotation tools enables users to enhance their engagement by adding notes and highlights. Moreover, instructors can easily monitor student progress, while users can conveniently download books for offline access.",
  image: Libraryatlearn,
};

export const CoursemanagementInfo = {
  title: "Course management",
  description:
    "Streamline your course creation process with Atlearn's user-friendly interface. Simply click a button to build your course, add categories, subcategories, subtopics, and course material. Enhance student engagement with lessons, assignments, quizzes, and a variety of interactive activities. Manage student data, assessments, attendance, performance monitoring, and communication seamlessly within the platform.",
};

export const studentMenuItems = [
  {
    label: "Course Progress",
    url: "/students#course-Progress-Info",
    icon: courseIcon, // image source for the icon
  },
  {
    label: "Assessment And Quiz",
    url: "/students#assessment-quiz",
    icon: AssessmentIcon, // image source for the icon
  },
  {
    label: "Efficient Student Data Management",
    url: "/students#efficient-student",
    icon: DatamangementIcon, // no image icon, using the dot instead
  },
  {
    label: "Course Management",
    url: "/students#course-management",
    icon: CoursemanagementIcon, // no image icon, using the dot instead
  },
  {
    label: "Customized learning Experiences",
    url: "/students#flexible-time",
    icon: CustomizedlearningExperiencesIcon, // no image icon, using the dot instead
  },
  {
    label: "Features for students",
    url: "/students#features",
    icon: FeaturesforstudentsIcon, // no image icon, using the dot instead
  },
  {
    label: "Book Management",
    url: "/students#book-management",
    icon: BookManagementIcon, // no image icon, using the dot instead
  },
];
